import React from "react";
import PropTypes from "prop-types";

import "./RichTextImage.scss";

const RichTextImage = ({ data }) => {
  return (
    <div className="RichTextImage">
      <figure className="RichTextImage__figure">
        <img
          className="RichTextImage__image RichTextImage__img-override"
          alt={data.image.alt}
          src={data.image.filename}
        />
        {(data.caption || data.show_link) && (
          <figcaption className="RichTextImage__caption">
            {data.caption}
            {data.show_link && (
              <span className="RichTextImage__link">
                <a href={data.link_address.url || data.link_address.cached_url}>
                  {data.link_text}
                </a>
              </span>
            )}
          </figcaption>
        )}
      </figure>
    </div>
  );
};

RichTextImage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      alt: PropTypes.string,
      filename: PropTypes.string,
    }),
    link_address: PropTypes.shape({
      url: PropTypes.string,
      cached_url: PropTypes.string,
    }),
    link_text: PropTypes.string,
    caption: PropTypes.string,
    show_link: PropTypes.bool,
  }),
};

export default RichTextImage;
