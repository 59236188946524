import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import has from "lodash/has";

import ArticlePromo from "../../components/ArticlePromo";
import AllNewsArticleText from "../../components/AllNewsArticleText";
import * as allComponents from "../../CMS/operations.jsx";
import Share from "../../components/Share";
import Loader from "../../components/Loader";

import "../../assets/scss/layouts/article-page.scss";

const SuccessStories = ({ id }) => {
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);

    allComponents
      .getBlocks(`success-stories/${id}`, "by_slugs")
      .then((response) => {
        const data = response.data.stories[0];

        setPageData(data);
      });
  }, [id]);

  return has(pageData, "content") ? (
    <section className="article-page">
      <ArticlePromo data={pageData.content} />

      <div className="article-page__content">
        <div className="article-page__column">
          <AllNewsArticleText data={pageData} />

          {has(pageData, "content.item_title") && (
            <Share
              url={window.location.href}
              quote={pageData.content.item_title}
            />
          )}
        </div>
        {/* === Related Stories === */}
      </div>
    </section>
  ) : (
    <Loader isHolder />
  );
};

SuccessStories.propTypes = {
  id: PropTypes.string,
};

export default SuccessStories;
