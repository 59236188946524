import React from "react";
import PropTypes from "prop-types";
import { richTextResolver } from "../../CMS/Storyblok";

import "./NoteBox.scss";

const NoteBox = ({ title, description, hasDescription }) => {
  return (
    <div className="note-box">
      {title && <h5>{title}</h5>}
      {hasDescription && (
        <div className="note-box__description">
          <div
            dangerouslySetInnerHTML={{ __html: richTextResolver(description) }}
          />
        </div>
      )}
    </div>
  );
};

NoteBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  hasDescription: PropTypes.array,
};

export default NoteBox;
