import {
  faEnvelope,
  faExternalLinkAlt,
  faFilePdf,
  faGlobe,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "markdown-to-jsx";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Share from "../Share";
import LinkButton from "../Links/LinkButton";
import "./JobDetail.scss";
import formatAssetUrl from "../../utils/format-asset-url";

export default () => {
  const { vacancyId } = useParams();
  const [jobData, setJobData] = useState();

  const isInstructorJob = jobData && jobData.tid === 2;

  useEffect(() => {
    const originalScrollBehaviour =
      document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = "smooth";

    return () => {
      document.documentElement.style.scrollBehavior = originalScrollBehaviour;
    };
  }, []);

  useEffect(() => {
    const fetchJob = async () => {
      const url = `${process.env.REACT_APP_BACKEND}/api/job/${vacancyId}`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);

      setJobData(data);
    };

    fetchJob();
  }, []);

  return !jobData ? null : (
    <section className="job-detail">
      <div className="job-detail__header">
        <div className="job-detail__container-wrapper">
          {!isInstructorJob ? (
            <Link to="/career-seekers/jobs">{"⟵ Back to Job Search"}</Link>
          ) : (
            <Link to="/instructors/jobs">{"⟵ Back to Job Search"}</Link>
          )}
          <h1>{jobData.title}</h1>
          <h2>{jobData.ename || jobData.pname}</h2>
          <Share
            url={window.location.href}
            quote={`${jobData.title} - ${jobData.ename || jobData.pname}`}
          />
        </div>
        <div className="job-detail__contact-card">
          <p className="job-detail__contact-label">
            <span className="job-detail__contact-label-label">Location</span>
            <span>
              {jobData.loc.length > 18 ? "See Description" : jobData.loc}
            </span>
          </p>
          <p className="job-detail__contact-label">
            <span className="job-detail__contact-label-label">Status</span>
            <span>{jobData.stat}</span>
          </p>
          <p className="job-detail__contact-label">
            <span className="job-detail__contact-label-label">Closing</span>
            <span>{jobData.dclose}</span>
          </p>
          <div>
            <LinkButton text="Contact Employer" href="#employer-information" />
          </div>
        </div>
      </div>

      <div className="job-detail__container-wrapper">
        <h3>Job Description</h3>
        <Markdown
          className="job-detail__description"
          options={{ forceBlock: true }}
        >
          {jobData.desc}
        </Markdown>
        <p>{jobData.loc.length > 18 && jobData.loc}</p>
        <h3 id="employer-information">Employer Information</h3>
        <h4>Address</h4>
        <address>
          <ul className="job-detail__address-list">
            <li>{jobData.add1}</li>
            <li>{jobData.add2}</li>
            <li>{jobData.add3}</li>
            <li>{jobData.add4}</li>
          </ul>

          <h4>Contact</h4>
          <ul className="job-detail__contact-list">
            <li>
              <span className="job-detail__green-icon">
                <FontAwesomeIcon icon={faUser} />
              </span>{" "}
              {jobData.cntct_name}
            </li>
            {jobData.cntct_email && (
              <li>
                <a href={`mailto:${jobData.cntct_email}`}>
                  <FontAwesomeIcon icon={faEnvelope} /> {jobData.cntct_email}
                </a>
              </li>
            )}
            {jobData.wsite && (
              <li>
                <a target="_blank" rel="noreferrer" href={jobData.wsite}>
                  <FontAwesomeIcon icon={faGlobe} /> {jobData.wsite}
                </a>
              </li>
            )}
          </ul>
        </address>
        {!isInstructorJob ? (
          <>
            <h4>Apprenticeship Information</h4>
            <ul className="job-detail__info-list">
              {jobData.tradeprofileurl && (
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={formatAssetUrl(jobData.tradeprofileurl)}
                  >
                    Occupational Profile <FontAwesomeIcon icon={faFilePdf} />
                  </a>
                </li>
              )}
              {jobData.tradeurl && (
                <li>
                  <a target="_blank" rel="noreferrer" href={jobData.tradeurl}>
                    {`${jobData.title} details`}{" "}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </li>
              )}
            </ul>
            <div className="job-detail__back-bottom">
              <Link to="/career-seekers/jobs">{"⟵ Back to Job Search"}</Link>
            </div>
          </>
        ) : (
          <>
            <h4>Instructor Information</h4>
            <ul className="job-detail__info-list">
              {jobData.tradeprofileurl && (
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={formatAssetUrl(jobData.instructorprofileurl)}
                  >
                    Occupational Profile <FontAwesomeIcon icon={faFilePdf} />
                  </a>
                </li>
              )}
            </ul>
            <div className="job-detail__back-bottom">
              <Link to="/instructors/jobs">{"⟵ Back to Job Search"}</Link>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
