import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";

import Modal from "../Modal";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./LightBoxGallery.scss";

const LightBoxGallery = ({
  initialDisplayID,
  handleCloseLightBoxGallery,
  images,
}) => {
  return (
    <Modal handleCloseModal={handleCloseLightBoxGallery}>
      <div className="LightBoxGallery">
        <Carousel
          selectedItem={initialDisplayID}
          dynamicHeight
          thumbWidth={208}
        >
          {images.map((item, key) => {
            return (
              <div key={key}>
                <img
                  className="LightBoxGallery__image"
                  alt="Ambassador"
                  src={item.image}
                />
                <div className="LightBoxGallery__name-badge legend">
                  <p className="LightBoxGallery__name-text">{item.name}</p>
                  <p className="LightBoxGallery__name-text">{item.company}</p>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </Modal>
  );
};

LightBoxGallery.propTypes = {
  initialDisplayID: PropTypes.number,
  handleCloseLightBoxGallery: PropTypes.func,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      company: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
};

export default LightBoxGallery;
