import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import has from "lodash/has";

import * as allComponents from "../../CMS/operations.jsx";
import EmployersSectionMenuConfig from "../../config/EmployersSectionMenuConfig";

import SEO from "../../SEO";
import SectionTitleMenu from "../../components/SectionTitleMenu";
import SteppersSubNav from "../../components/SteppersSubNav/SteppersSubNav.jsx";
import InfoHeader from "../../components/InfoHeader";
import GetStartedDescription from "../../components/GetStartedDescription";
import NavSidebar from "../../components/NavSidebar";
import Share from "../../components/Share";
import Loader from "../../components/Loader";

import "./ApplyPage.scss";

const SelectPage = ({
                      programSlug,
                      sectionSlug,
                      programmes,
                      programSections,
                      steppers,
                    }) => {
  const [programmesData, setProgrammesData] = useState(programmes);
  const [programSectionsData, setProgramSectionsData] =
    useState(programSections);

  const [currentProgram, setCurrentProgram] = useState({});
  const [currentSection, setCurrentSection] = useState({});
  const [steppersSection, setSteppersSection] = useState(steppers);

  const textLinkBack = "Back";
  const backURL = `/employers/how-to-apply/select/${programSlug}/${sectionSlug}`;
  const tabURL = "/employers/how-to-apply/select";

  useEffect(() => {
    window.scrollTo(0, 0);
    if (programmesData.length) return;
    allComponents.find2().then((response) => {
      const data = response.data.stories[0].content.section;

      const getProgrammes = data.filter(
        (block) => block.component === "employers_programmes",
      )[0].programmes_blocks;
      setProgrammesData(getProgrammes);

      const getSteppers = data.filter(
        (block) => block.component === "steppers_sub_nav",
      )[0];
      setSteppersSection(getSteppers);
    });
  }, [programmesData]);

  useEffect(() => {
    if (programSectionsData.length) return;
    if (programmesData.length) {
      const uuidsList = programmesData
        .map((programme) => programme.content.program_section_blocks.map(
          (programSection) => typeof programSection === "object"
            ? programSection.uuid
            : programSection, // it's just an uuid directly
        ))
        .flat()
        .join(",");

      if (uuidsList) {
        allComponents.getBlocks(uuidsList).then((response) => {
          const sectionDataNew = response.data.stories;
          setProgramSectionsData(sectionDataNew);
        });
      }
    }
  }, [programmesData, programSectionsData]);

  useEffect(() => {
    const getCurrentProgram = programmesData.filter(
      (item) => item.slug === programSlug,
    )[0];
    setCurrentProgram(getCurrentProgram);

    const getCurrentSection = programSectionsData.filter(
      (item) => item.slug === sectionSlug,
    )[0];
    setCurrentSection(getCurrentSection);
  }, [
    programSectionsData,
    programSlug,
    programmesData,
    sectionSlug,
    currentSection,
  ]);

  return (
    <section className="apply step-container">
      {currentSection && currentSection.content && (
        <SEO
          image={currentSection.content.section_image}
          url={window.location.href}
          description={currentSection.content.section_description}
        />
      )}
      <SectionTitleMenu menuList={EmployersSectionMenuConfig} />

      {has(steppersSection, "component") ? (
        <section className="section-sub-nav">
          <div className="page-section steppers_sub_nav__holder">
            <div className="container">
              <SteppersSubNav data={steppersSection} />
            </div>
          </div>
        </section>
      ) : (
        <Loader isHolder />
      )}

      {currentProgram && currentSection ? (
        <>
          <InfoHeader
            currentProgram={currentProgram}
            currentSection={currentSection}
            isNextButton={false}
            textLinkBack={textLinkBack}
            urlLinkBack={backURL}
          />

          <div className="two-columns aside-top">
            <div className="two-columns__content">
              {has(currentSection, "content.content_step3") && (
                <GetStartedDescription
                  descriptionData={currentSection.content.content_step3}
                />
              )}

              {has(currentSection, "content.section_title") && (
                <Share
                  url={window.location.href}
                  quote={currentSection.content.section_title}
                />
              )}
            </div>

            <div className="two-columns__aside">
              <NavSidebar
                currentProgram={currentProgram}
                currentSection={currentSection}
                programSections={programSectionsData}
                tabURL={tabURL}
              />
            </div>
          </div>
        </>
      ) : (
        <Loader isHolder />
      )}
    </section>
  );
};

const mapStateToProps = (store) => {
  const { programmes, programSections } = store.programmReducer;
  const { steppers } = store.steppersReducer;
  return { programmes, programSections, steppers };
};

SelectPage.defaultProps = {
  programmes: [],
  programSections: [],
  steppers: {},
};

SelectPage.propTypes = {
  programSlug: PropTypes.string,
  sectionSlug: PropTypes.string,
  programmes: PropTypes.array,
  programSections: PropTypes.array,
  steppers: PropTypes.object,
};

export default connect(mapStateToProps)(SelectPage);
