import classNames from "classnames/bind";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Component from "../../CMS/Component.jsx";
import * as allComponents from "../../CMS/operations.jsx";
import useObjectDependency from "../../hooks/useObjectDependency";
import { PageProvider } from "../Contexts/PageContext";
import Loader from "../Loader";
import "./CMSPageData.scss";

const CMSPageData = ({ propsData, pageName }) => {
  const [pageData, setPageData] = useState([]);
  const [componentData, setComponentData] = useState(null);

  useEffect(() => {
    allComponents[pageName]().then((response) => {
      const data = response.data.stories[0].content.section;

      setComponentData(data);
      setPageData(data);
    });
  }, [pageName, useObjectDependency(componentData)]);

  useEffect(() => {
    if (componentData) {
      const expectedBlocks = [
        "hero_main",
        "sliders",
        "employers_programmes",
        "success_stories",
        "hiring_companies",
        "faq_section",
        "myth_busters",
        "get_involved_section",
        "testimonials",
        "testimonials",
        "hero_main",
        "cookie_policy",
      ];

      const expectedProgrammesBlocks = [
        "career_seekers_programmes",
        "soon_programmes",
        "employers_programmes",
        "programmes",
      ];

      window.storyblok.on("input", (event) => {
        if (
          event.story.content.section
            .map((item) => item.component)
            .join(",") !== componentData.map((item) => item.component).join(",")
        )
          return;
        const newData = event.story.content.section.map((component, idx) => {
          const elData = component;
          if (expectedProgrammesBlocks.includes(component.component)) {
            elData.programmes_blocks = componentData[idx].programmes_blocks;
            return elData;
          }
          if (expectedBlocks.includes(component.component)) {
            elData.blocks = componentData[idx].blocks;
            return elData;
          }
          return component;
        });
        setPageData(newData);
      });
    }
  }, [setPageData, useObjectDependency(componentData)]);

  return pageData.length ? (
    <>
      {pageData.map((section) => {
        const backgroundCondition = (backgroundSlug) => {
          return (
            section &&
            section[backgroundSlug] &&
            section[backgroundSlug].color &&
            section[backgroundSlug].color !== "#f40000"
          );
        };

        const skewMainBackground = backgroundCondition("skew_main_color") && (
          <span
            className="skew-main-background"
            style={{ backgroundColor: section.skew_main_color.color }}
          />
        );
        const skewTopBackground = backgroundCondition("skew_top_color") && (
          <span
            className="skew-top-background"
            style={{ backgroundColor: section.skew_top_color.color }}
          />
        );
        const skewBottomBackground = backgroundCondition(
          "skew_bottom_color",
        ) && (
          <span
            className="skew-bottom-background"
            style={{ backgroundColor: section.skew_bottom_color.color }}
          />
        );

        const itemClass = classNames({
          "page-section": true,
          "has-top-background": skewTopBackground,
          "has-main-background": skewMainBackground,
          "has-bottom-background": skewBottomBackground,
          [`${section.component}__holder`]: section.component,
        });

        const { _uid: id } = section;

        return (
          <section className={itemClass} key={id}>
            {skewTopBackground}
            {skewMainBackground}
            {skewBottomBackground}
            <div className="container">
              <PageProvider value={pageName}>
                <Component
                  blok={section}
                  props={propsData}
                  pageName={pageName}
                />
              </PageProvider>
            </div>
          </section>
        );
      })}
    </>
  ) : (
    <Loader isHolder />
  );
};

CMSPageData.propTypes = {
  propsData: PropTypes.object,
  pageName: PropTypes.string,
};

export default CMSPageData;
