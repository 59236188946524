import React from "react";
import PropTypes from "prop-types";
import "./AmbassadorCard.scss";
import cross from "../../assets/img/svg/cross-hatch.svg";

const AmbassadorCard = ({ itemNo, title, name, company, image, onClick }) => {
  return (
    <div className="AmbassadorCard" onClick={() => onClick(itemNo)}>
      <img alt={name} src={image} className="AmbassadorCard__thumbnail" />
      <div className="AmbassadorCard__description">
        <p className="AmbassadorCard__text-title">{title}</p>
        <p className="AmbassadorCard__text-name">{name}</p>
        <p className="AmbassadorCard__text-company">{company}</p>
        <img
          src={cross}
          alt="Cross Hatch Graphic"
          className="AmbassadorCard__graphic"
        />
      </div>
    </div>
  );
};

AmbassadorCard.propTypes = {
  itemNo: PropTypes.number,
  title: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
};

export default AmbassadorCard;
