import React from "react";
import PropTypes from "prop-types";

const ShareItem = ({ item, url, quote }) => {
  const ButtonComponent = item.button;

  return (
    <li key={item.text}>
      <ButtonComponent url={url} quote={quote}>
        {item.icon}

        <span className="text">{item.text}</span>
      </ButtonComponent>
    </li>
  );
};

ShareItem.propTypes = {
  item: PropTypes.object,
  url: PropTypes.string,
  quote: PropTypes.string,
};

export default ShareItem;
