export default function getParallaxData(className, windowDimensions) {
  let startX = null;
  let startY = null;
  let endX = null;
  let endY = null;
  let scrollTriggerEnd = "";

  if (className === "introduction") {
    startX = 80;
    startY = -20;
    endX = 0;
    endY = -40;
    scrollTriggerEnd = "20%";

    if (windowDimensions > 1024) {
      startX = 100;
      startY = -40;
      endX = 0;
      endY = -60;
    }
  }

  if (className === "parents-educators") {
    startX = -45;
    startY = 20;
    endX = 0;
    endY = 0;
    scrollTriggerEnd = "20%";

    if (windowDimensions > 768) {
      startX = -50;
      startY = 20;
      endX = -10;
      endY = 0;
    }

    if (windowDimensions > 1200) {
      startX = -100;
      startY = 50;
      endX = 10;
      endY = -20;
    }
  }

  if (className === "parents-friends") {
    startX = -70;
    startY = 50;
    endX = -10;
    endY = 0;
    scrollTriggerEnd = "20%";

    if (windowDimensions > 768) {
      startX = -60;
      startY = 50;
      endX = -20;
      endY = 30;
    }

    if (windowDimensions > 1024) {
      startX = -100;
      startY = 50;
      endX = 10;
      endY = -20;
    }
  }

  if (className === "potential-employers") {
    startX = 80;
    startY = 30;
    endX = 0;
    endY = 10;
    scrollTriggerEnd = "20%";

    if (windowDimensions > 1024) {
      startX = 60;
      startY = 60;
      endX = 0;
      endY = 20;
    }

    if (windowDimensions > 1200) {
      startX = 100;
      startY = 60;
      endX = 0;
      endY = 20;
    }
  }

  return [
    {
      x: startX,
      y: startY,
    },
    {
      x: endX,
      y: endY,
      scrollTrigger: {
        scrub: true,
        end: scrollTriggerEnd,
      },
    },
  ];
}
