import React from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./LinkBack.scss";

const LinkBack = ({ history, to = "", color, text }) => {
  const handleClick = () => !to && history.goBack();

  return (
    <Link className="link-back" to={to} style={{ color }} onClick={handleClick}>
      <span className="text">{text}</span>
    </Link>
  );
};

LinkBack.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  to: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(LinkBack);
