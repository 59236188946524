import React from "react";
import SectionTitleMenu from "../../components/SectionTitleMenu";
import CMSPageData from "../../components/CMSPageData";

import PotentialEmployers from "../../assets/img/Employers/PotentialEmployers.png";
import EmployersMenuConfig from "../../config/EmployersMenuConfig";

export default () => {
  return (
    <>
      <SectionTitleMenu menuList={EmployersMenuConfig} />
      <div className="potential-employers">
        <CMSPageData
          propsData={{
            img: PotentialEmployers,
            className: "potential-employers",
          }}
          pageName={"potentialEmployersPage"}
        />
      </div>
    </>
  );
};
