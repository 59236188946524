import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./NavSidebar.scss";

const NavSidebar = ({
                      currentProgram,
                      currentSection,
                      programSections,
                      tabURL,
                    }) => {
  if (!currentProgram.name) return null;
  let sectionIDList = [];
  let getColor = null;

  sectionIDList = currentProgram.content.program_section_blocks;
  getColor =
    currentProgram.content &&
    currentProgram.content.program_background_color.color;

  return (
    <section className="nav-sidebar">
      <h2>Also in this section</h2>
      <ul className="nav-sidebar__list">
        {sectionIDList.map((programmeSection) => {
          const uuid = typeof programSection === "object"
            ? programmeSection.uuid
            : programmeSection; // it's just an uuid directly

          const currentIndex = programSections.findIndex(
            (item) => item.uuid === uuid,
          );
          if (currentIndex === -1) return null;
          return (
            <li
              key={uuid}
              className={currentSection.uuid === uuid ? "active-item" : null}
            >
              <Link
                to={`${tabURL}/${currentProgram.slug}/${programSections[currentIndex].slug}`}
                onClick={() =>
                  currentSection.uuid !== uuid && scroll.scrollToTop()
                }
                style={{ backgroundColor: getColor }}
              >
                <span className="text">
                  {programSections[currentIndex].content.section_title}
                </span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

NavSidebar.propTypes = {
  currentProgram: PropTypes.object,
  currentSection: PropTypes.object,
  programSections: PropTypes.array,
  tabURL: PropTypes.string,
};

export default NavSidebar;
