import _ from "lodash";
import React from "react";
import ReactDOMServer from "react-dom/server";
import StoryblokClient from "storyblok-js-client";
import originalRichTextSchema from "storyblok-js-client/source/schema";
import Button from "../components/static/Button";
import MixedContent from "../components/static/MixedContent";
import YTVideo from "../components/static/YTVideo";

const token = process.env.REACT_APP_STORYBLOK_TOKEN;
const version = process.env.REACT_APP_STORYBLOK_VERSION;

const richTextSchema = _.cloneDeep(originalRichTextSchema);

// overrides domain for storyblok assets
richTextSchema.marks.link = (node) => {
  const modifiedNode = _.cloneDeep(node);

  if (process.env.REACT_APP_PAGE_DOMAIN && node.attrs.linktype === "asset")
    modifiedNode.attrs.href = node.attrs.href.replace(
      "//a.storyblok.com",
      `//content.${process.env.REACT_APP_PAGE_DOMAIN}`,
    );

  return originalRichTextSchema.marks.link(modifiedNode);
};

const Storyblok = new StoryblokClient({
  accessToken: token,
  cache: {
    clear: "auto",
    type: "memory",
  },
  richTextSchema,
});

Storyblok.setComponentResolver((component, blok) => {
  switch (component) {
    case "yt_video_embed":
      return ReactDOMServer.renderToStaticMarkup(<YTVideo data={blok} />);
    case "mixed_content":
      return ReactDOMServer.renderToStaticMarkup(<MixedContent data={blok} />);
    case "button_link":
      return ReactDOMServer.renderToStaticMarkup(<Button data={blok} />);
    default:
      return "<p>This component is not available</p>";
  }
});

export const richTextResolver = (data) => {
  return Storyblok.richTextResolver.render(data);
};

const sectionsAll = [
  "sliders.blocks",
  "programmes.programmes_blocks",
  "success_stories.blocks",
  "hiring_companies.blocks",
  "myth_busters.blocks",
  "faq_section.blocks",
  "get_involved_section.blocks",
  "testimonials.blocks",
  "soon_programmes.programmes_blocks",
  "career_seekers_programmes.programmes_blocks",
  "career_seekers_program.program_section_blocks",
  "career_seekers_program_section.section_faq_blocks",
  "employers_programmes.programmes_blocks",
  "employers_program_section.section_faq_blocks",
  "employers_program_section.section_company_logos",
  "hero_main.blocks",
  "all_news.blocks",
  "all_news_promo.blocks",
  "competitions.blocks",
  "competitions_promo.blocks",
  "announcements.blocks",
  "announcements_promo.blocks",
  "events.blocks",
  "events_promo.blocks",
  "about_page_tabs.blocks",
  "already_an_apprentice_tabs.blocks",
  "providers.blocks_providers",
  "providers.blocks_counties",
  "news_gallery.blocks",
].join(",");

export const storyRequest = (
  page,
  urlCDN,
  sortParams = null,
  sortBy = "by_uuids",
) =>
  new Promise((resolve, reject) => {
    const options = {
      per_page: 100,
      starts_with: page,
      resolve_relations: sectionsAll,
      [sortBy]: sortParams,
    };

    if (version) options.version = version;

    Storyblok.get(urlCDN, options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// init window.storyblock
window.storyblok.init({
  accessToken: token,
});

window.storyblok.on(["published"], () => {
  window.location.reload(true);
});

window.storyblok.pingEditor(() => {
  if (window.storyblok.inEditor) {
    window.storyblok.enterEditmode();
  }
});
