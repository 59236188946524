import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "./Modal.scss";

const Modal = ({ handleCloseModal, children }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
  }, []);

  const onCloseModal = () => {
    document.body.classList.remove("modal-open");
    handleCloseModal();
  };
  return (
    <section className="Modal">
      <div onClick={() => onCloseModal()} className="Modal__mask"></div>
      <button className="Modal__close-button" onClick={() => onCloseModal()}>
        &#10005;
      </button>
      <div className="Modal__content">{children}</div>
    </section>
  );
};

Modal.propTypes = {
  handleCloseModal: PropTypes.func,
  children: PropTypes.any,
};

export default Modal;
