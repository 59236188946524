import React from "react";
import PropTypes from "prop-types";

import "./ModalVideo.scss";

const ModalVideo = ({ videoURL, handleCloseVideo, title }) => {
  return (
    <section className="modal-video">
      <button onClick={() => handleCloseVideo()}>&#10005;</button>
      <span onClick={() => handleCloseVideo()} className="mask" />
      <iframe
        src={`https://www.youtube.com/embed/${videoURL}`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
        title={title}
      />
    </section>
  );
};

ModalVideo.propTypes = {
  videoURL: PropTypes.string,
  title: PropTypes.string,
  handleCloseVideo: PropTypes.func,
};

export default ModalVideo;
