import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import has from "lodash/has";

import * as allComponents from "../../CMS/operations.jsx";
import EmployersSectionMenuConfig from "../../config/EmployersSectionMenuConfig";

import SEO from "../../SEO";
import SectionTitleMenu from "../../components/SectionTitleMenu";
import SteppersSubNav from "../../components/SteppersSubNav/SteppersSubNav.jsx";
import InfoHeader from "../../components/InfoHeader";
import NavSidebar from "../../components/NavSidebar";
import CompanyLogos from "../../components/CompanyLogos";
import FAQ from "../../components/FAQ";
import Share from "../../components/Share";
import Loader from "../../components/Loader";

import "./SelectPage.scss";

const SelectPage = ({
                      programSlug,
                      sectionSlug,
                      programmes,
                      programSections,
                      steppers,
                    }) => {
  const [programmesData, setProgrammesData] = useState(programmes);
  const [programSectionsData, setProgramSectionsData] =
    useState(programSections);

  const [currentProgram, setCurrentProgram] = useState({});
  const [currentSection, setCurrentSection] = useState({});
  const [faqSections, setFaqSections] = useState({});
  const [steppersSection, setSteppersSection] = useState(steppers);
  const [companyLogos, setCompanyLogos] = useState({});

  const textLinkBack = "Back to Apprenticeships";
  const textLinkNext = "Apply Now to Become an Employer";
  const backURL = "/employers/how-to-apply/find";
  const nextURL = `/employers/how-to-apply/apply/${programSlug}/${sectionSlug}`;
  const tabURL = "/employers/how-to-apply/select";

  useEffect(() => {
    window.scrollTo(0, 0);
    if (programmesData.length) return;
    allComponents.find2().then((response) => {
      const data = response.data.stories[0].content.section;

      const getProgrammes = data.filter(
        (block) => block.component === "employers_programmes",
      )[0].programmes_blocks;
      setProgrammesData(getProgrammes);

      const getSteppers = data.filter(
        (block) => block.component === "steppers_sub_nav",
      )[0];
      setSteppersSection(getSteppers);
    });
  }, [programmesData]);

  useEffect(() => {
    if (programSectionsData.length) return;
    if (programmesData.length) {
      const uuidsList = programmesData
        .map((programme) => programme.content.program_section_blocks.map(
          (programSection) => typeof programSection === "object"
            ? programSection.uuid
            : programSection,
        ))
        .flat()
        .join(",");

      if (uuidsList) {
        allComponents.getBlocks(uuidsList).then((response) => {
          const sectionDataNew = response.data.stories;
          setProgramSectionsData(sectionDataNew);
        });
      }
    }
  }, [programmesData, programSectionsData]);

  useEffect(() => {
    const getCurrentProgram = programmesData.filter(
      (item) => item.slug === programSlug,
    )[0];
    setCurrentProgram(getCurrentProgram);

    const getCurrentSection = programSectionsData.filter(
      (item) => item.slug === sectionSlug,
    )[0];
    setCurrentSection(getCurrentSection);

    if (getCurrentSection && getCurrentSection.content) {
      const sectionFaqTitle = getCurrentSection.content.section_faq_title
        ? getCurrentSection.content.section_faq_title
        : "FAQ";
      const faqData = {
        title: sectionFaqTitle,
        blocks: getCurrentSection.content.section_faq_blocks,
      };
      setFaqSections(faqData);
    }
  }, [
    programSectionsData,
    programSlug,
    programmesData,
    sectionSlug,
    currentSection,
  ]);

  useEffect(() => {
    if (currentSection && currentSection.content) {
      const companyLogosData = {};
      companyLogosData.title = currentSection.content
        .section_company_logos_title
        ? currentSection.content.section_company_logos_title
        : null;
      companyLogosData.btn_text = currentSection.content
        .section_company_logos_btn_text
        ? currentSection.content.section_company_logos_btn_text
        : null;
      companyLogosData.company_logos = currentSection.content
        .section_company_logos.length
        ? currentSection.content.section_company_logos
        : null;
      companyLogosData.company_url = nextURL;

      setCompanyLogos(companyLogosData);
    }
  }, [currentSection, nextURL]);

  return (
    <section className="select step-container">
      {currentSection && currentSection.content && (
        <SEO
          image={currentSection.content.section_image}
          url={window.location.href}
          description={currentSection.content.section_description}
        />
      )}
      <SectionTitleMenu menuList={EmployersSectionMenuConfig} />

      {has(steppersSection, "component") ? (
        <section className="section-sub-nav">
          <div className="page-section steppers_sub_nav__holder">
            <div className="container">
              <SteppersSubNav data={steppersSection} />
            </div>
          </div>
        </section>
      ) : (
        <Loader isHolder />
      )}

      {currentProgram && currentSection ? (
        <>
          <InfoHeader
            currentProgram={currentProgram}
            currentSection={currentSection}
            isNextButton={true}
            textLinkBack={textLinkBack}
            textLinkNext={textLinkNext}
            urlLinkBack={backURL}
            urlLinkNext={nextURL}
          />

          <div className="two-columns aside-top">
            <div className="two-columns__content">
              {companyLogos && companyLogos.company_logos && (
                <CompanyLogos data={companyLogos} />
              )}

              {faqSections && faqSections.title && <FAQ data={faqSections} />}

              {has(currentSection, "content.section_title") && (
                <Share
                  url={window.location.href}
                  quote={currentSection.content.section_title}
                />
              )}
            </div>

            <div className="two-columns__aside">
              <NavSidebar
                currentProgram={currentProgram}
                currentSection={currentSection}
                programSections={programSectionsData}
                tabURL={tabURL}
              />
            </div>
          </div>
        </>
      ) : (
        <Loader isHolder />
      )}
    </section>
  );
};

const mapStateToProps = (store) => {
  const { programmes, programSections } = store.programmReducer;
  const { steppers } = store.steppersReducer;
  return { programmes, programSections, steppers };
};

SelectPage.defaultProps = {
  programmes: [],
  programSections: [],
  steppers: {},
};

SelectPage.propTypes = {
  programSlug: PropTypes.string,
  sectionSlug: PropTypes.string,
  programmes: PropTypes.array,
  programSections: PropTypes.array,
  steppers: PropTypes.object,
};

export default connect(mapStateToProps)(SelectPage);
