import { useState, useEffect } from "react";

export default function useWindowDimensions(params = "") {
  /**
   *
   * @returns {{width: number, height: number}}
   */
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    /**
     * @returns void
     */
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  switch (params) {
    case "width":
      return windowDimensions.width;

    case "height":
      return windowDimensions.height;

    default:
      return windowDimensions;
  }
}
