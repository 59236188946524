import React from "react";
import PropTypes from "prop-types";

import "./YTVideo.scss";

const YTVideo = ({ data }) => {
  return (
    <div className="yt-video">
      <iframe
        className="yt-player"
        title={data.link}
        src={`https://www.youtube.com/embed/${data.link}?rel=0`}
      />
    </div>
  );
};

YTVideo.propTypes = {
  data: PropTypes.shape({
    link: PropTypes.string,
  }),
};

export default YTVideo;
