import { combineReducers } from "redux";

import programmReducer from "./programmes/reducer";
import steppersReducer from "./steppers/reducer";

const rootReducer = combineReducers({
  programmReducer,
  steppersReducer,
});

export default rootReducer;
