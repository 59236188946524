import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { richTextResolver } from "../../CMS/Storyblok";
import * as allComponents from "../../CMS/operations.jsx";
import Button from "../Button";

import "./CookiePolicy.scss";

function CookiePolicy() {
  const [data, setData] = useState(null);
  const [isCookiePolicy, setCookiePolicy] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("solasCookiePolicy")) setCookiePolicy(false);
  }, []);

  useEffect(() => {
    if (isCookiePolicy) return;
    allComponents.getBlocks("cookie-policy", "by_slugs").then((response) => {
      setData(response.data.stories[0]);
    });
  }, [isCookiePolicy]);

  const acceptCookies = () => {
    setCookiePolicy(true);
    localStorage.setItem("solasCookiePolicy", true);
  };

  if (!data || !data.content || isCookiePolicy) return null;

  return (
    <div className="cookie-policy">
      <div className="area">
        <div
          className="policy-content"
          dangerouslySetInnerHTML={{
            __html: richTextResolver(data.content.policy_content),
          }}
        />

        <div className="aside">
          <a href="#" id="changePreferences">
            {data.content.cookie_policy_link_text}
          </a>
          <Button onClick={acceptCookies}>
            {data.content.accept_cookies_text}
          </Button>
        </div>
      </div>
    </div>
  );
}

CookiePolicy.propTypes = {
  data: PropTypes.object,
};

export default CookiePolicy;
