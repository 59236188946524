import { useRef } from "react";
import _ from "lodash";

/**
 * Use this hook if you want to use javascript object as one of useEffect's dependencies. Does deep comparison on its' fields to look for a change.
 *
 * Example: <br/>
 * <pre>
 * const objDep = useObjectDependency(obj)
 * useEffect(cb, [objDep])
 * </pre>
 */
export default (obj) => {
  const ref = useRef(obj);
  if (!_.isEqual(obj, ref.current)) {
    ref.current = obj;
  }
  return ref.current;
};
