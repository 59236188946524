export default [
  {
    title: "POTENTIAL EMPLOYERS",
    link: "/employers/potential-employers",
  },
  {
    title: "HOW TO APPLY",
    link: "/employers/how-to-apply",
  },
  {
    title: "CURRENT EMPLOYERS",
    link: "/employers/current-employers",
  },
  {
    title: "Employer Ambassadors",
    link: "/employers/employer-ambassadors",
  },
];
