import React from "react";
import PropTypes from "prop-types";
import has from "lodash/has";

import { richTextResolver } from "../../CMS/Storyblok";
import getEventDate from "../../utils/get-event-date";

import "./_AllNewsArticleText.scss";

const AllNewsArticleText = ({ data }) => {
  const isSuccessStory = data.content.component === "success_story";

  return (
    <>
      {!isSuccessStory && (
        <dl className="published-on">
          <dt>PUBLISHED ON:</dt>
          <dd>
            {getEventDate(
              data?.content.override_published_date || data.published_at,
            )}
          </dd>
        </dl>
      )}
      {has(data, "content.page_content") && (
        <section
          className="article-text"
          dangerouslySetInnerHTML={{
            __html: richTextResolver(data.content.page_content),
          }}
        />
      )}
    </>
  );
};

AllNewsArticleText.propTypes = {
  data: PropTypes.object,
};

export default AllNewsArticleText;
