const initialState = {
  steppers: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "STEPPERS_LOADED":
      return {
        steppers: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
