import React from "react";
import PropTypes from "prop-types";

import "./_Button.scss";

function Button({ children, onClick }) {
  return (
    <button onClick={onClick} className="button">
      {children}
    </button>
  );
}
Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default Button;
