import React, { useState } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import has from "lodash/has";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

import Heading from "../Heading";
import Card from "../Card";

import "./SortedSliders.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SortedSliders = ({ data }) => {
  const [isNumbers, setIsNumbers] = useState(false);

  /* settings for the Slider */
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    lazyLoad: false,
    arrows: true,
    onReInit: () =>
      setIsNumbers(
        Boolean(document.querySelector(".sorted-sliders .slick-dots")),
      ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  const sortData = data.blocks.sort((itemA, itemB) => {
    const dateA = new Date(itemA.published_at);
    const dateB = new Date(itemB.published_at);
    return dateB - dateA;
  });

  return (
    <section className="sorted-sliders">
      <Heading
        titleColor={data.title_color && data.title_color.color}
        title={data.sliders_title}
        rightContent={
          has(data, "add_link_view_all.cached_url") && (
            <a href={data.add_link_view_all.cached_url}>
              {data.sliders_link_view_all_title}
              {data.add_icon_video_to_link && (
                <FontAwesomeIcon icon={faYoutube} />
              )}
            </a>
          )
        }
      />

      <div className="slick-slider__wrapper">
        <Slider {...sliderSettings}>
          {sortData.map((item) => (
            <Card key={item.id} data={item} />
          ))}
        </Slider>

        {isNumbers && (
          <div className="slick-slider__wrapper__counter">
            <span className="current">01</span>
            <span className="total">
              {data.blocks.length < 10
                ? `0${data.blocks.length}`
                : data.blocks.length}
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

SortedSliders.propTypes = {
  data: PropTypes.shape({
    add_icon_video_to_link: PropTypes.bool,
    sliders_title: PropTypes.string,
    title_color: PropTypes.object,
    add_link_view_all: PropTypes.object,
    sliders_link_view_all_title: PropTypes.string,
    blocks: PropTypes.array,
  }),
};

export default SortedSliders;
