import React from "react";
import About from "../components/About";
import AllNewsArticles from "../components/AllNewsArticles";
import AllNewsPromo from "../components/AllNewsPromo";
import ApprenticeshipJobs from "../components/ApprenticeshipJobs/ApprenticeshipJobs.jsx";
import ContentWithSideTabs from "../components/ContentWithSideTabs";
import CookiePolicy from "../components/CookiePolicy";
import ExploreOurProgrammes from "../components/ExploreOurProgrammes";
import FAQ from "../components/FAQ";
import GetInvolved from "../components/GetInvolved";
import HeroMain from "../components/HeroMain";
import HiringCompanies from "../components/HiringCompanies";
import LocalProvidersMaps from "../components/LocalProvidersMaps";
import MythBustersEducationToolKit from "../components/MythBusters-EducationToolKit";
import NewsGallery from "../components/NewsGallery";
import PhotoGallery from "../components/PhotoGallery";
import SectionTitleBlock from "../components/SectionTitleBlock";
import SimpleContent from "../components/SimpleContent";
import SortedSliders from "../components/SortedSliders";
import ArticleBlurb from "../components/static/ArticleBlurb";
import Button from "../components/static/Button";
import MixedContent from "../components/static/MixedContent";
import RichTextImage from "../components/static/RichTextImage";
import SteppersSubNav from "../components/SteppersSubNav";
import SuccessStories from "../components/SuccessStories";
import Testimonials from "../components/Testimonials";

const Components = {
  page_head: SectionTitleBlock,
  myth_busters: MythBustersEducationToolKit,
  programmes: ExploreOurProgrammes,
  success_stories: SuccessStories,
  get_involved_section: GetInvolved,
  info_text: About,
  faq_section: FAQ,
  testimonials: Testimonials,
  hiring_companies: HiringCompanies,
  sliders: SortedSliders,
  hero_main: HeroMain,
  soon_programmes: ExploreOurProgrammes,
  career_seekers_programmes: ExploreOurProgrammes,
  employers_programmes: ExploreOurProgrammes,
  steppers_sub_nav: SteppersSubNav,
  all_news: AllNewsArticles,
  all_news_promo: AllNewsPromo,
  competitions: AllNewsArticles,
  competitions_promo: AllNewsPromo,
  events: AllNewsArticles,
  events_promo: AllNewsPromo,
  announcements: AllNewsArticles,
  announcements_promo: AllNewsPromo,
  about_page_tabs: ContentWithSideTabs,
  already_an_apprentice_tabs: ContentWithSideTabs,
  simple_content: SimpleContent,
  providers: LocalProvidersMaps,
  cookie_policy: CookiePolicy,
  photo_gallery: PhotoGallery,
  mixed_content: MixedContent,
  article_blurb: ArticleBlurb,
  rich_text_image: RichTextImage,
  button_link: Button,
  news_gallery: NewsGallery,
  apprenticeship_jobs: ApprenticeshipJobs,
};

const Component = ({ blok, props }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const ComponentNew = Components[blok.component];
    return <ComponentNew data={blok} props={props} />;
  }
  return "";
};

export default Component;
