import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import "./_SectionTitleMenu.scss";

const SectionTitleMenu = ({ menuList }) => {
  return (
    <div className="section-title-menu">
      <ul className="section-title-menu__list">
        {menuList.map((props, index) => (
          <li key={index}>
            <NavLink to={props.link} activeClassName="active-item">
              {props.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

SectionTitleMenu.propTypes = {
  menuList: PropTypes.array,
  pageName: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
};

export default SectionTitleMenu;
