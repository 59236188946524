import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./HeroSlider.scss";

let columnCounter = 0;
let imageCounter = 3;

const HeroSlider = ({ data }) => {
  const getURL = (number) => data[number].content.hero_slider_image;

  const [imgArray, setImgArray] = useState([getURL(0), getURL(1), getURL(2)]);

  const increaseImage = () => {
    setImgArray((arr) => {
      const createArr = arr.slice();
      createArr[columnCounter] = getURL(imageCounter);
      return createArr;
    });

    columnCounter = columnCounter < 2 ? columnCounter + 1 : 0;

    imageCounter = imageCounter < data.length - 1 ? imageCounter + 1 : 0;
  };

  useEffect(() => {
    const interval = setInterval(() => increaseImage(), 1200);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    return () => {
      columnCounter = 0;
      imageCounter = 3;
    };
  }, []);

  return (
    <div className="hero-slider">
      <div className="hero-slider__holder">
        <ul className="hero-slider__list">
          {imgArray.map((i, idx) => (
            <li key={idx}>
              <img src={i} alt={`Visual ${idx + 1}`} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

HeroSlider.propTypes = {
  data: PropTypes.array,
};

export default HeroSlider;
