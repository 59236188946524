import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import mainRoutesConfig from "../../config/MainRoutsConfig.jsx";

const PagesRoute = ({ location }) => (
  <main className="main">
    <Switch location={location}>
      {mainRoutesConfig.map((route) => (
        <Route
          key={route.path || route.render}
          path={route.path}
          component={route.component}
          render={route.render}
          exact={route.exact}
        />
      ))}
    </Switch>
  </main>
);

PagesRoute.propTypes = {
  location: PropTypes.object,
};

export default withRouter(PagesRoute);
