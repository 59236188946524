export default [
  {
    title: "EDUCATORS",
    link: "/career-advisors/educators",
  },
  {
    title: "PARENTS & FRIENDS",
    link: "/career-advisors/parents-and-friends",
  },
];
