import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import has from "lodash/has";

import ArticlePromo from "../ArticlePromo";
import AllNewsArticleText from "../AllNewsArticleText";
import Share from "../Share";
import SortedSliders from "../SortedSliders";
import Component from "../../CMS/Component.jsx";
import "../../assets/scss/layouts/article-page.scss";
import getEventDate from "../../utils/get-event-date";

const NewsArticleTypeManager = ({ pageData, relatedNewsData }) => {
  const [legacyAnnouncementItem, setIsLegacyAnnouncementItem] = useState(true);
  useEffect(() => {
    const legacyComponents = [
      "announcement_item",
      "news_item",
      "events_item",
      "success_story",
    ];
    if (legacyComponents.includes(pageData.content.component)) {
      setIsLegacyAnnouncementItem(true);
    } else {
      setIsLegacyAnnouncementItem(false);
    }
  }, [pageData]);
  return (
    <section className="article-page">
      {has(pageData, "content") && <ArticlePromo data={pageData.content} />}
      <div className="article-page__content">
        <div className="article-page__column">
          {legacyAnnouncementItem ? (
            has(pageData, "content") && <AllNewsArticleText data={pageData} />
          ) : (
            <div>
              <dl className="published-on">
                <dt>PUBLISHED ON:</dt>
                <dd>
                  {getEventDate(
                    pageData?.content.override_published_date ||
                      pageData.published_at,
                  )}
                </dd>
              </dl>
              {has(pageData, "content") &&
                pageData.content.body.map((comp, key) => (
                  <Component key={key} blok={comp} />
                ))}
            </div>
          )}

          {has(pageData, "content.item_title") && (
            <Share
              url={window.location.href}
              quote={pageData.content.item_title}
            />
          )}
        </div>

        {has(relatedNewsData, "blocks") && (
          <SortedSliders data={relatedNewsData} />
        )}
      </div>
    </section>
  );
};

NewsArticleTypeManager.propTypes = {
  pageData: PropTypes.object,
  relatedNewsData: PropTypes.object,
};

export default NewsArticleTypeManager;
