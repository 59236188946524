import React from "react";
import SectionTitleMenu from "../../components/SectionTitleMenu";
import CMSPageData from "../../components/CMSPageData/CMSPageData";

import ParentsFriends from "../../assets/img/CareerAdvisors/ParentsFriends.png";
import CareerAdvisorsMenuConfig from "../../config/CareerAdvisorsMenuConfig";

export default () => {
  return (
    <>
      <SectionTitleMenu menuList={CareerAdvisorsMenuConfig} />
      <div className="parents-friends">
        <CMSPageData
          propsData={{ img: ParentsFriends, className: "parents-friends" }}
          pageName={"parentsAndFriendsPage"}
        />
      </div>
    </>
  );
};
