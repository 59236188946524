import React from "react";
import CMSPageData from "../../components/CMSPageData";
import SectionTitleMenu from "../../components/SectionTitleMenu";
import CareerSeekersSectionMenuConfig from "../../config/CareerSeekersSectionMenuConfig";

import Introduction from "../../assets/img/CareerSeekers/Introduction.png";
import IntroductionPeople from "../../assets/img/CareerSeekers/Introduction-people.svg";

export default () => {
  return (
    <>
      <SectionTitleMenu menuList={CareerSeekersSectionMenuConfig} />
      <div className="educators">
        <CMSPageData
          propsData={{
            img: Introduction,
            childImage: IntroductionPeople,
            className: "introduction",
          }}
          pageName={"careerSeekersIntroduction"}
        />
      </div>
    </>
  );
};
