import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import "./ProviderCard.scss";

function ProviderCard({ provider, distanceText, selected, onClick }) {
  return (
    <article
      className={classNames({
        ProviderCard: true,
        selected,
      })}
      onClick={onClick}
    >
      <div className="ProviderCard__distance">{distanceText || "&nbsp;"}</div>
      <h5>{provider.name}</h5>
      <address className="ProviderCard__address">{provider.address}</address>
      <div className="ProviderCard__info-row">
        <a
          className="ProviderCard__info-row__phone"
          href={`tel:${provider.phone}`}
        >
          {provider.phone}
        </a>
        <a
          className="ProviderCard__info-row__website"
          href={provider.website_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {provider.website_text}
        </a>
      </div>
    </article>
  );
}

ProviderCard.propTypes = {
  provider: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    website_url: PropTypes.string,
    website_text: PropTypes.string,
  }),
  distanceText: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default ProviderCard;
