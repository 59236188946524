import React from "react";
import PropTypes from "prop-types";

import "./LinkSecondary.scss";

const LinkSecondary = ({
  text, href, isBlank, handleOnClick, color
}) => {
  return (
    /* eslint-disable-next-line react/jsx-no-target-blank */ // Disabled, the rule isn't violated
    <a
      href={href}
      target={isBlank ? "_blank" : null}
      rel={isBlank ? "noopener noreferrer" : null}
      className="link-secondary"
      onClick={handleOnClick}
      style={{ color }}
    >
      <span className="text">{text}</span>
    </a>
  );
};

LinkSecondary.defaultProps = {
  isBlank: false,
  handleOnClick: null,
};

LinkSecondary.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  isBlank: PropTypes.bool,
  handleOnClick: PropTypes.func,
  color: PropTypes.string,
};

export default LinkSecondary;
