export default [
  {
    title: "ABOUT",
    link: "/more/about",
  },
  {
    title: "APPRENTICESHIP DIRECTORY",
    link: "/more/apprenticeship-directory",
  },
  {
    title: "YOUR LOCAL PROVIDER",
    link: "/more/your-local-provider",
  },
  // {
  //   title: "eLEARNING PORTAL",
  //   link: "/more/e-learning-portal",
  // },
];
