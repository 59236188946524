import React from "react";
import PropTypes from "prop-types";

import "./Heading.scss";

const Heading = ({ title, titleColor, rightContent }) => (
  <div className="heading">
    {title && <h2 style={{ color: titleColor }}>{title}</h2>}
    {rightContent && <div className="heading__right">{rightContent}</div>}
  </div>
);

Heading.defaultProps = {
  titleColor: null,
  rightContent: null,
};

Heading.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  rightContent: PropTypes.object,
};

export default Heading;
