import React from "react";
import PropTypes from "prop-types";
import has from "lodash/has";

import HeaderFooter from "../Heading";
import Card from "../Card";

import "./NewsGallery.scss";

const NewsGallery = ({ data }) => {
  return (
    <div className="NewsGallery">
      <HeaderFooter
        titleColor={data.title_color && data.title_color.color}
        title={data.title}
        rightContent={
          has(data, "view_all_link.cached_url") && (
            <a href={data.view_all_link.cached_url}>{data.view_all_text}</a>
          )
        }
      />
      <div className="NewsGallery__gallery">
        {data.blocks.map((item) => (
          <div key={item.id} className="NewsGallery__card-wrapper">
            <Card data={item} />
          </div>
        ))}
      </div>
      <HeaderFooter
        rightContent={
          has(data, "view_all_link.cached_url") && (
            <a href={data.view_all_link.cached_url}>{data.view_all_text}</a>
          )
        }
      />
    </div>
  );
};

NewsGallery.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    title_color: PropTypes.object,
    view_all_text: PropTypes.string,
    view_all_link: PropTypes.object,
    blocks: PropTypes.array,
  }),
};

export default NewsGallery;
