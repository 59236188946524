import React from "react";

import SkewTopFooter from "../../components/Skews/SkewTopFooter";
import CMSPageData from "../../components/CMSPageData/CMSPageData";

import "./Homepage.scss";

export default () => {
  return (
    <section className="homepage">
      <CMSPageData pageName={"homepage"} />
      <SkewTopFooter />
    </section>
  );
};
