import React from "react";
import PropTypes from "prop-types";
import { richTextResolver } from "../../CMS/Storyblok";

import "./_GetStartedDescription.scss";

const GetStartedDescription = ({ descriptionData }) => {
  return (
    <section className="get-started-description">
      <div
        className="get-started-description__holder"
        dangerouslySetInnerHTML={{ __html: richTextResolver(descriptionData) }}
      />
    </section>
  );
};

GetStartedDescription.propTypes = {
  descriptionData: PropTypes.object,
};

export default GetStartedDescription;
