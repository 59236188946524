import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faDownload } from "@fortawesome/free-solid-svg-icons";
import DownloadLogo from "../../../assets/img/svg/arrow-down.svg";
import "./ActionButton.scss";

const ActionButton = ({
  onAction,
  actionText,
  unActionText,
  actionIcon = "plus",
  unactionIcon = "minus",
  isExpanded = false,
}) => {
  const icons = {
    plus: <FontAwesomeIcon icon={faPlus} />,
    minus: <FontAwesomeIcon icon={faMinus} />,
    download2: <FontAwesomeIcon icon={faDownload} />,
    download: <img className="ActionButton__svg" src={DownloadLogo} />,
  };
  return (
    <button className="ActionButton" onClick={onAction}>
      {isExpanded ? (
        <div>
          <span className="ActionButton__text">{unActionText}</span>
          <span className="ActionButton__icon">{icons[unactionIcon]}</span>
        </div>
      ) : (
        <div>
          <span className="ActionButton__text">{actionText}</span>
          <span className="ActionButton__icon">{icons[actionIcon]}</span>
        </div>
      )}
    </button>
  );
};

ActionButton.propTypes = {
  isExpanded: PropTypes.bool,
  onAction: PropTypes.func,
  actionText: PropTypes.string,
  unActionText: PropTypes.string,
  actionIcon: PropTypes.string,
  unactionIcon: PropTypes.string,
};

export default ActionButton;
