import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TextTruncate from "react-text-truncate";

import "./_SuccessStories.scss";

const SuccessStories = ({ data }) => (
  <>
    <div className="page-section_head">
      <div className="page-section_head__holder">
        <h2 style={{ color: data.title_color && data.title_color.color }}>
          {data.title}
        </h2>
        {(data.link.url || data.link.cached_url) && (
          <a href={data.link.cached_url}>{data.link_copy}</a>
        )}
      </div>
    </div>

    <ul className="stories-list">
      {data.blocks.map(
        (item) =>
          item.content && (
            <li key={item.content.item_title}>
              <Link to={`/success-stories/${item.slug}`}>
                <img src={item.content.image} alt={item.content.item_title} />
                <span className="stories-list__link">
                  {item.content.link_copy}
                  <span>⟶</span>
                </span>
                <span className="story-description">
                  <TextTruncate
                    line={3}
                    element="span"
                    truncateText="…"
                    text={item.content.item_title}
                  />
                </span>
              </Link>
            </li>
          ),
      )}
    </ul>
  </>
);

SuccessStories.propTypes = {
  data: PropTypes.object,
};

export default SuccessStories;
