import React from "react";

import SectionTitleMenu from "../../components/SectionTitleMenu";
import EmployersMenuConfig from "../../config/EmployersMenuConfig";
import CMSPageData from "../../components/CMSPageData";

export default () => {
  return (
    <>
      <SectionTitleMenu menuList={EmployersMenuConfig} />
      <CMSPageData pageName="employerAmbassadorsPage" />
    </>
  );
};
