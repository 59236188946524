import React, { useEffect } from "react";

import EmployersSectionMenuConfig from "../../config/EmployersSectionMenuConfig";
import SectionTitleMenu from "../../components/SectionTitleMenu";
import CMSPageData from "../../components/CMSPageData/CMSPageData";

import "./FindPage.scss";

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SectionTitleMenu menuList={EmployersSectionMenuConfig} />

      <section className="find">
        <CMSPageData
          pageName={"find"}
          propsData={{
            className: "find",
          }}
        />
      </section>
    </>
  );
};
