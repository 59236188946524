import React from "react";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import ShareItem from "../ShareItem";

import "./Share.scss";

const Share = ({ url, quote }) => {
  const shareData = [
    {
      text: "Facebook",
      icon: <FontAwesomeIcon icon={faFacebookSquare} />,
      button: FacebookShareButton,
    },
    {
      text: "Twitter",
      icon: <FontAwesomeIcon icon={faTwitterSquare} />,
      button: TwitterShareButton,
    },
    {
      text: "Linkedin",
      icon: <FontAwesomeIcon icon={faLinkedin} />,
      button: LinkedinShareButton,
    },
  ];

  return (
    <div className="share">
      <strong className="share__title">Share:</strong>
      <ul className="share__list">
        {shareData.map((item) => (
          <ShareItem key={item.text} item={item} url={url} quote={quote} />
        ))}
      </ul>
    </div>
  );
};

Share.propTypes = {
  url: PropTypes.string,
  quote: PropTypes.string,
};

export default Share;
