export const programmesLoadedAction = (newProgrammes) => {
  return {
    type: "PROGRAMMES_LOADED",
    payload: newProgrammes,
  };
};

export const programSectionsLoadedAction = (newProgramSections) => {
  return {
    type: "PROGRAM_SECTIONS_LOADED",
    payload: newProgramSections,
  };
};
