import React from "react";
import { Link } from "react-router-dom";
import "./Page404.scss";

export default () => (
  <div
    style={{
      marginTop: "10vh",
      marginLeft: "10vh",
      marginRight: "10vh",
      textAlign: "left",
    }}
  >
    <h2>Error 404.</h2>
    <p>
      Sorry, we cant find the page you requested. <br /> We have recently
      updated our website so the information you are looking should still be
      available.{" "}
    </p>
    <p>
      You might like to{" "}
      <Link to="/career-seekers/get-started/discover">
        browse our list of apprenticeship programmes
      </Link>{" "}
      or <Link to="/">start from the home page</Link>
    </p>
    <p>Thank you.</p>
  </div>
);
