import React from "react";
import PropTypes from "prop-types";

const CompanyLogos = ({ data }) =>
  data.company_logos.map((item) => {
    return (
      <li key={item.name}>
        <img src={item.content.company_logo_image} alt={data.name} />
      </li>
    );
  });

CompanyLogos.propTypes = {
  data: PropTypes.object,
};

export default CompanyLogos;
