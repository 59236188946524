import React, { useEffect } from "react";

import SectionTitleMenu from "../../components/SectionTitleMenu";
import CMSPageData from "../../components/CMSPageData/CMSPageData";
import NewsEventsTitleMenuConfig from "../../config/NewsEventsTitleMenuConfig";

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SectionTitleMenu menuList={NewsEventsTitleMenuConfig} />

      <section className="all-news">
        <CMSPageData pageName={"events"} />
      </section>
    </>
  );
};
