import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import useWindowDimensions from "../../hooks/windowViewPort";
import SocialNetworks from "../Socials";

import "./_AsideMainMenu.scss";

const AsideMainMenu = ({
  mainMenu,
  openCloseMainMenuHandler,
  mainMenuRoutes,
  mainMenuSubRoutes,
  showSecondMobileSubMenu,
  openCloseSecondSubMenuHandler,
}) => (
  <>
    {useWindowDimensions("width") <= 1024 && (
      <nav
        className={
          mainMenu.isMainMenuOpen ? "aside-nav aside-open" : "aside-nav"
        }
      >
        <ul className="aside-nav__menu">
          {mainMenuRoutes.map((props, index) => (
            <li key={index} className={props.isSubmenu ? "submenu-item" : ""}>
              {props.isSubmenu ? (
                <p onClick={openCloseSecondSubMenuHandler}>
                  {props.text}
                  <span>+</span>
                </p>
              ) : (
                <NavLink to={props.link} onClick={openCloseMainMenuHandler}>
                  {props.text}
                </NavLink>
              )}
            </li>
          ))}
        </ul>

        <SocialNetworks
          closeMainMenu={openCloseMainMenuHandler}
          showIcons={{
            facebook: true,
            youtube: true,
            twitter: true,
            linkedin: true,
            instagram: true,
          }}
        />
      </nav>
    )}

    {useWindowDimensions("width") <= 1024 && (
      <nav
        className={
          showSecondMobileSubMenu.isShowSecondSubMenu
            ? "aside-sub-nav aside-sub-nav-open"
            : "aside-sub-nav"
        }
      >
        <section className="aside-sub-nav__submenu">
          <div className="back-to-main-menu">
            <button onClick={openCloseSecondSubMenuHandler}>⟵</button>
            <span>Back to Main Menu</span>
          </div>

          <strong>More</strong>
          <ul className="aside-sub-nav__submenu-list">
            {mainMenuSubRoutes.map((propsSub) => (
              <li key={propsSub.link}>
                <NavLink to={propsSub.link} onClick={openCloseMainMenuHandler}>
                  {propsSub.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </section>

        <SocialNetworks
          closeMainMenu={openCloseMainMenuHandler}
          showIcons={{
            facebook: true,
            youtube: true,
            twitter: true,
            linkedin: true,
            instagram: true,
          }}
        />
      </nav>
    )}
  </>
);

AsideMainMenu.propTypes = {
  mainMenu: PropTypes.object,
  openCloseMainMenuHandler: PropTypes.func,
  mainMenuRoutes: PropTypes.array,
  mainMenuSubRoutes: PropTypes.array,
  showSecondMobileSubMenu: PropTypes.object,
  openCloseSecondSubMenuHandler: PropTypes.func,
  isSubmenu: PropTypes.bool,
  text: PropTypes.string,
  link: PropTypes.string,
};

export default AsideMainMenu;
