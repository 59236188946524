import React, { useState } from "react";
import PropTypes from "prop-types";
import FAQContentItem from "../FAQContentItem";
import NoteBlock from "../NoteBlock";
import BtnExpandAll from "./BtnExpandAll";

import "./_FAQ.scss";

const FAQ = ({ data }) => {
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const expandAll = () => setIsAllExpanded(!isAllExpanded);

  const { note_title: noteTitle, note_description: noteDescription } = data;

  const hasDescription =
    noteDescription &&
    noteDescription.content[0] &&
    noteDescription.content[0].content;
  return (
    <>
      <div className="page-section_head">
        <h2>{data.title}</h2>
        <BtnExpandAll onClick={expandAll} isAllExpanded={isAllExpanded} />
      </div>
      <ul className="faq-list">
        {data.blocks.map((props) => (
          <li className="faq-list-item" key={props.id}>
            <FAQContentItem item={props} isAllExpanded={isAllExpanded} />
          </li>
        ))}
      </ul>
      {(noteTitle || hasDescription) && (
        <NoteBlock
          hasDescription={hasDescription}
          title={noteTitle}
          description={noteDescription}
        />
      )}
    </>
  );
};

FAQ.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
};

export default FAQ;
