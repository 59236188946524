import React from "react";
import PropTypes from "prop-types";

import "./_MythBusters-EducationToolKit.scss";

const MythBustersEducationToolKit = ({ data }) => (
  <>
    <div className="page-section_head">
      <div className="page-section_head__holder">
        <h2 style={{ color: data.title_color && data.title_color.color }}>
          {data.title}
        </h2>
        {(data.link.url || data.link.cached_url) && (
          <a href={data.link.url || data.link.cached_url}>{data.link_copy}</a>
        )}
      </div>
    </div>

    <ul className="features-list">
      {data.blocks.map((item, index) => (
        <li
          key={index}
          style={{
            background: `linear-gradient(6deg, ${item.content.secondary_color.color} 13%, ${item.content.primary_color.color} 13.3%)`,
          }}
        >
          <strong>{item.content.title}</strong>
          <p>{item.content.text}</p>

          {(item.content.link.url || item.content.link.cached_url) && (
            <div className="link-block">
              <a
                href={
                  item.content.link.url || `/${item.content.link.cached_url}/`
                }
              >
                {item.content.link_copy}
                <span>⟶</span>
              </a>
            </div>
          )}
        </li>
      ))}
    </ul>
  </>
);

MythBustersEducationToolKit.propTypes = {
  data: PropTypes.object,
};

export default MythBustersEducationToolKit;
