import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/windowViewPort";
import DecorationLetter from "../Decorations/DecorationLetter";
import LinkButton from "../Links/LinkButton";
import { usePagination } from "../Pagination";
import "./ApprenticeshipJobs.scss";

const Jobs = ({ data }) => {
  const history = useHistory();
  const jobsTableRef = useRef(null);
  const [sortRowByCol, setSortRowByCol] = useState({ value: null, sort: null });

  const [fetchedJobs, setFetchedJobs] = useState();
  const [fetchedCounties, setFetchedCounties] = useState();
  const [fetchedTrades, setFetchedTrades] = useState();

  const [location, setLocation] = useState();
  const [apprenticeship, setApprenticeship] = useState();
  const [keyword, setKeyword] = useState("");

  // need to be separate from the 3 above as api params update on clicking search / reset button and the other ones update on changing the fields themselves
  const [locationApiParam, setLocationApiParam] = useState();
  const [apprenticeshipApiParam, setApprenticeshipApiParam] = useState();
  const [keywordApiParam, setKeywordApiParam] = useState();

  const [openedRow, setOpenedRow] = useState(null);

  const sortColumnApiParam = sortRowByCol.value?.apiAttribute;
  const sortDirectionApiParam = sortRowByCol.sort;

  const jobType = data.jobs_type;
  const isInstructorJob = jobType === "2";

  const cols = [
    { text: "Ref.", apiAttribute: "REFNO" },
    { text: "Organisation", apiAttribute: undefined },
    { text: "Location", apiAttribute: undefined },
    {
      text: isInstructorJob ? "Trade" : "Apprenticeship",
      apiAttribute: "TRADE",
    },
    { text: "Status", apiAttribute: "STAT" },
    { text: "Closing", apiAttribute: "DCLOSE" },
  ];

  const [Pagination, page, pageSize, { setPage }] = usePagination(
    fetchedJobs?.rowCount,
    fetchedJobs?.totalRows,
    {
      onPaginationChange: (paginationChangeData) => {
        if (
          paginationChangeData.oldPage !== paginationChangeData.newPage ||
          paginationChangeData.newPageSize < paginationChangeData.oldPageSize
        ) {
          // scrolls on top of jobs table
          window.scrollTo(
            0,
            jobsTableRef.current.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              58,
          );
        }
      },
    },
  );

  const setApiParams = (locationAp, apprenticeshipAp, keywordAp) => {
    setLocationApiParam(locationAp);
    setApprenticeshipApiParam(apprenticeshipAp);
    setKeywordApiParam(keywordAp);
    setPage(0);
  };

  const resetFilter = () => {
    setLocation(undefined);
    setApprenticeship(undefined);
    setKeyword("");
    setApiParams();
  };

  useEffect(() => {
    const fetchCounties = async () => {
      const url = `${process.env.REACT_APP_BACKEND}/api/ref/counties`;
      const response = await fetch(url);
      const counties = await response.json();
      setFetchedCounties(counties);
    };
    const fetchTrades = async () => {
      const url = `${process.env.REACT_APP_BACKEND}/api/ref/assessedtrades`;
      const response = await fetch(url);
      const trades = await response.json();
      setFetchedTrades(trades);
    };

    fetchCounties();
    fetchTrades();
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      const url = `${process.env.REACT_APP_BACKEND}/api/job/search?start=${
        page * pageSize
      }&pgsize=${pageSize}${
        sortDirectionApiParam ? `&sdir=${sortDirectionApiParam}` : ""
      }&scol=${sortColumnApiParam || "defaultsortorder"}${
        locationApiParam ? `&countyId=${locationApiParam}` : ""
      }${apprenticeshipApiParam ? `&tradeId=${apprenticeshipApiParam}` : ""}${
        keywordApiParam ? `&search=${keywordApiParam}` : ""
      }${jobType ? `&typeId=${jobType}` : ""}`;

      const response = await fetch(url);
      const jobs = await response.json();

      setFetchedJobs(jobs);
    };

    fetchJobs();
  }, [
    page,
    pageSize,
    sortDirectionApiParam,
    sortColumnApiParam,
    locationApiParam,
    apprenticeshipApiParam,
    keywordApiParam,
  ]);

  const sortRow = (selectedRowIndex) => {
    setPage(0);
    if (cols[selectedRowIndex] === sortRowByCol.value) {
      if (sortRowByCol.sort === "desc")
        return setSortRowByCol({ value: null, sort: null });
      return setSortRowByCol({ value: cols[selectedRowIndex], sort: "desc" });
    }
    return setSortRowByCol({ value: cols[selectedRowIndex], sort: "asc" });
  };

  const renderIcon = (col) => {
    if (col === sortRowByCol.value && sortRowByCol.sort === "asc") {
      return (
        <div className="jobs__sort">
          <svg
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.12109 0.625C0.929688 0.625 0.765625 0.707031 0.65625 0.816406C0.519531 0.953125 0.464844 1.11719 0.464844 1.28125C0.464844 1.47266 0.519531 1.63672 0.65625 1.74609L3.91016 5C4.01953 5.13672 4.18359 5.19141 4.375 5.19141C4.53906 5.19141 4.70312 5.13672 4.83984 5L8.09375 1.74609C8.20312 1.63672 8.28516 1.47266 8.28516 1.28125C8.28516 1.11719 8.20312 0.953125 8.09375 0.816406C7.95703 0.707031 7.79297 0.625 7.62891 0.625H1.12109Z"
              fill="#316D74"
            />
          </svg>
        </div>
      );
    }

    if (col === sortRowByCol.value && sortRowByCol.sort === "desc") {
      return (
        <div className="jobs__sort">
          <svg
            className="jobs__sort--pad-bot"
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.12109 5.375C0.929688 5.375 0.765625 5.29297 0.65625 5.18359C0.519531 5.04688 0.464844 4.88281 0.464844 4.71875C0.464844 4.52734 0.519531 4.36328 0.65625 4.25391L3.91016 1C4.01953 0.863281 4.18359 0.808594 4.375 0.808594C4.53906 0.808594 4.70312 0.863281 4.83984 1L8.09375 4.25391C8.20312 4.36328 8.28516 4.52734 8.28516 4.71875C8.28516 4.88281 8.20312 5.04688 8.09375 5.18359C7.95703 5.29297 7.79297 5.375 7.62891 5.375H1.12109Z"
              fill="#316D74"
            />
          </svg>
        </div>
      );
    }

    return (
      <div className="jobs__sort">
        <svg
          width="10"
          height="21"
          viewBox="0 0 10 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.740141"
            d="M1.12109 11.625C0.929688 11.625 0.765625 11.707 0.65625 11.8164C0.519531 11.9531 0.464844 12.1172 0.464844 12.2812C0.464844 12.4727 0.519531 12.6367 0.65625 12.7461L3.91016 16C4.01953 16.1367 4.18359 16.1914 4.375 16.1914C4.53906 16.1914 4.70312 16.1367 4.83984 16L8.09375 12.7461C8.20312 12.6367 8.28516 12.4727 8.28516 12.2812C8.28516 12.1172 8.20312 11.9531 8.09375 11.8164C7.95703 11.707 7.79297 11.625 7.62891 11.625H1.12109ZM8.09375 8.75391C8.20312 8.89062 8.28516 9.05469 8.28516 9.21875C8.28516 9.41016 8.20312 9.57422 8.09375 9.68359C7.95703 9.82031 7.79297 9.875 7.62891 9.875H1.12109C0.929688 9.875 0.765625 9.82031 0.65625 9.68359C0.519531 9.57422 0.464844 9.41016 0.464844 9.21875C0.464844 9.05469 0.519531 8.89062 0.65625 8.75391L3.91016 5.5C4.01953 5.39062 4.18359 5.30859 4.375 5.30859C4.53906 5.30859 4.70312 5.39062 4.83984 5.5L8.09375 8.75391Z"
            fill="#BFC2C4"
          />
        </svg>
      </div>
    );
  };

  const renderJobs = (width) => {
    if (width > 1040) {
      return (
        <>
          <table className="jobs__table">
            <thead>
              <tr>
                {cols.map((col, i) => (
                  <th
                    className={col.apiAttribute ? "sortable" : ""}
                    onClick={() => {
                      if (col.apiAttribute) sortRow(i);
                    }}
                    key={col.text}
                  >
                    <p>{col.text}</p>
                    {col.apiAttribute && renderIcon(col)}
                  </th>
                ))}
              </tr>
            </thead>
            {fetchedJobs?.items.length > 0 && (
              <tbody>
                {fetchedJobs.items.map((row) => {
                  return (
                    <tr
                      key={row.refno}
                      onClick={() => history.push(`jobs/${row.vid}`)}
                    >
                      <td>{row.refno}</td>
                      <td>{row.ename || row.pname}</td>
                      <td>{row.loc}</td>
                      <td>{row.trade}</td>
                      <td>{row.stat}</td>
                      <td>{row.dclose}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </>
      );
    }

    if (width > 720) {
      return (
        <div>
          <div className="jobs__tablet-head-spacer">
            <div className="jobs__tablet-header">
              {cols.map((c, i) => {
                if (i > 3) return null;
                return (
                  <div
                    className={c.apiAttribute ? "sortable" : ""}
                    onClick={() => {
                      if (c.apiAttribute) sortRow(i);
                    }}
                    key={c.text}
                  >
                    <p>{c.text}</p>
                    {c.apiAttribute && renderIcon(c)}
                  </div>
                );
              })}
            </div>
            <div />
          </div>
          {fetchedJobs?.items.map((row, i) => {
            return (
              <div key={row.refno} className="jobs__tablet-container">
                <div className="jobs__tablet-row">
                  <div className="jobs__tablet-columns">
                    <div>{row.refno}</div>
                    <div>{row.ename || row.pname}</div>
                    <div>{row.loc}</div>
                    <div>{row.trade}</div>
                  </div>
                  {openedRow === i && (
                    <>
                      <div className="jobs__tablet-hidden-row">
                        <div className="jobs__tablet-column">
                          <div className="jobs__tablet-column-title">
                            Status
                          </div>
                          <div className="jobs__tablet-column-value">
                            {row.stat}
                          </div>
                        </div>
                        <div className="jobs__tablet-column">
                          <div className="jobs__tablet-column-title">
                            Closing
                          </div>
                          <div className="jobs__tablet-column-value">
                            {row.dclose}
                          </div>
                        </div>
                      </div>
                      <div className="jobs__tablet-details-button">
                        <LinkButton
                          text="View Job Details"
                          href={`jobs/${row.vid}`}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div
                  onClick={() => {
                    setOpenedRow((prev) => (i === prev ? null : i));
                  }}
                >
                  <div className="jobs__tablet-row-chev">
                    {openedRow === i ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <div>
        <div className="jobs__mobile-head-spacer">
          <div className="jobs__mobile-header">
            {cols.map((c, i) => {
              if (i > 1) return null;
              return (
                <div
                  className={c.apiAttribute ? "sortable" : ""}
                  onClick={() => {
                    if (c.apiAttribute) sortRow(i);
                  }}
                  key={c.text}
                >
                  <p>{c.text}</p>
                  {c.apiAttribute && renderIcon(c)}
                </div>
              );
            })}
          </div>
          <div />
        </div>
        {fetchedJobs?.items.map((row, i) => {
          return (
            <div key={row.refno} className="jobs__mobile-container">
              <div className="jobs__mobile-row">
                <div className="jobs__mobile-columns">
                  <div>{row.refno}</div>
                  <div>{row.ename || row.pname}</div>
                </div>
                {openedRow === i && (
                  <>
                    <div className="jobs__mobile-hidden-row">
                      <div className="jobs__mobile-column">
                        <div className="jobs__mobile-column-title">Status</div>
                        <div className="jobs__mobile-column-value">
                          {row.loc}
                        </div>
                      </div>
                      <div className="jobs__mobile-column">
                        <div className="jobs__mobile-column-title">Status</div>
                        <div className="jobs__mobile-column-value">
                          {row.trade}
                        </div>
                      </div>
                      <div className="jobs__mobile-column">
                        <div className="jobs__mobile-column-title">Status</div>
                        <div className="jobs__mobile-column-value">
                          {row.stat}
                        </div>
                      </div>
                      <div className="jobs__mobile-column">
                        <div className="jobs__mobile-column-title">Closing</div>
                        <div className="jobs__mobile-column-value">
                          {row.dclose}
                        </div>
                      </div>
                    </div>
                    <div className="jobs__tablet-details-button">
                      <LinkButton
                        text="View Job Details"
                        href={`jobs/${row.vid}`}
                      />
                    </div>
                  </>
                )}
              </div>
              <div
                onClick={() => {
                  setOpenedRow((prev) => (i === prev ? null : i));
                }}
              >
                <div className="jobs__mobile-row-chev">
                  {openedRow === i ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <section className="jobs">
        <div className="jobs__header-skew" />
        <div className="jobs__header">
          <div className="jobs__container-wrapper">
            <div className="jobs__input-group">
              <Listbox
                className="jobs__listbox"
                as="div"
                value={location}
                onChange={setLocation}
              >
                <Listbox.Label>Location</Listbox.Label>
                <Listbox.Button>
                  {({ open }) => (
                    <>
                      <span>
                        {location
                          ? location.referenceDescription
                          : "Select a location"}
                      </span>
                      <span className="jobs__input-icon">
                        {location ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={(e) => {
                              setLocation(undefined);
                              e.stopPropagation();
                            }}
                          />
                        ) : open ? (
                          <FontAwesomeIcon icon={faChevronUp} />
                        ) : (
                          <FontAwesomeIcon icon={faChevronDown} />
                        )}
                      </span>
                    </>
                  )}
                </Listbox.Button>
                <Listbox.Options>
                  {fetchedCounties &&
                    fetchedCounties.length > 0 &&
                    fetchedCounties.map((county) => (
                      <Listbox.Option
                        as={Fragment}
                        key={county.referenceId}
                        value={county}
                      >
                        {({ active, selected }) => (
                          <li
                            className={`${active ? "active" : ""} ${
                              selected ? "selected" : ""
                            }`}
                          >
                            {selected && <FontAwesomeIcon icon={faCheck} />}
                            {county.referenceDescription}
                          </li>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Listbox>

              <Listbox
                className="jobs__listbox"
                as="div"
                value={apprenticeship}
                onChange={setApprenticeship}
              >
                <Listbox.Label>
                  {isInstructorJob ? "Trade" : "Apprenticeship"}
                </Listbox.Label>
                <Listbox.Button>
                  {({ open }) => (
                    <>
                      <span>
                        {apprenticeship
                          ? apprenticeship.referenceDescription
                          : "Select a type"}
                      </span>
                      <span className="jobs__input-icon">
                        {apprenticeship ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={(e) => {
                              setApprenticeship(undefined);
                              e.stopPropagation();
                            }}
                          />
                        ) : open ? (
                          <FontAwesomeIcon icon={faChevronUp} />
                        ) : (
                          <FontAwesomeIcon icon={faChevronDown} />
                        )}
                      </span>
                    </>
                  )}
                </Listbox.Button>
                <Listbox.Options>
                  {fetchedTrades &&
                    fetchedTrades.length > 0 &&
                    fetchedTrades.map((trade) => (
                      <Listbox.Option
                        as={Fragment}
                        key={trade.referenceId}
                        value={trade}
                      >
                        {({ active, selected }) => (
                          <li
                            className={`${active ? "active" : ""} ${
                              selected ? "selected" : ""
                            }`}
                          >
                            {selected && <FontAwesomeIcon icon={faCheck} />}
                            {trade.referenceDescription}
                          </li>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Listbox>

              <div className="jobs__input">
                <label>Keyword</label>
                <span className="jobs__input-container">
                  <input
                    type="input"
                    value={keyword}
                    style={{ width: keyword ? undefined : "100%" }}
                    onChange={(event) => {
                      const value = event.target.value;
                      setKeyword(value);
                    }}
                    placeholder="E.g. Manufacturing"
                  />
                  {keyword && (
                    <span
                      onClick={() => setKeyword("")}
                      className="jobs__input-icon"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  )}
                </span>
              </div>

              <div className="jobs__buttons">
                <button
                  className="search-button"
                  onClick={() =>
                    setApiParams(
                      location?.referenceId,
                      apprenticeship?.referenceId,
                      keyword,
                    )
                  }
                >
                  <span className="text">Search</span>
                </button>
                <button
                  className="reset-button"
                  onClick={resetFilter}
                  style={{
                    visibility:
                      location || apprenticeship || keyword
                        ? "visible"
                        : "hidden",
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="jobs__content">
          <div className="jobs__content-unskew" />
          <div className="jobs__container-wrapper" ref={jobsTableRef}>
            {renderJobs(useWindowDimensions("width"))}
            {fetchedJobs?.items.length > 0 ? (
              <Pagination />
            ) : (
              <div className="jobs__empty">
                <div>
                  <span>Nothing found matching your search.</span>
                  <span>Try different search criteria.</span>
                  <button onClick={resetFilter}>Reset filters</button>
                </div>
                <DecorationLetter color="#47878E" size={290} />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

Jobs.propTypes = {
  data: PropTypes.shape({
    jobs_type: PropTypes.string,
  }),
};

export default Jobs;
