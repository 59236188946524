import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import { richTextResolver } from "../../CMS/Storyblok";

import "./_SimpleContent.scss";

function SimpleContent({ data }) {
  const mainClass = classNames({
    "simple-content": true,
    "simple-content__small-size": data.small_size,
  });

  return (
    <div
      className={mainClass}
      dangerouslySetInnerHTML={{ __html: richTextResolver(data.content) }}
    />
  );
}

SimpleContent.propTypes = {
  data: PropTypes.object,
};

export default SimpleContent;
