import React from "react";
import { BrowserRouter } from "react-router-dom";

import Header from "./components/Header";
import PagesRoute from "./components/PagesRoute";
import Footer from "./components/Footer";
import "./utils/check-mobile";

/* Basic styles */
import "./assets/scss/_base.scss";

const App = () => {
  return (
    <BrowserRouter>
      <Header />

      <PagesRoute />

      <Footer />
    </BrowserRouter>
  );
};

export default App;
