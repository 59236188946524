export default [
  {
    title: "ALL NEWS",
    link: "/news-events/news",
  },
  {
    title: "COMPETITIONS",
    link: "/news-events/competitions",
  },
  {
    title: "EVENTS",
    link: "/news-events/events",
  },
  {
    title: "ANNOUNCEMENTS",
    link: "/news-events/announcements",
  },
];
