import React from "react";
import PropTypes from "prop-types";
import has from "lodash/has";

import LinkBack from "../Links/LinkBack";
import LinkDouble from "../Links/LinkDouble";
import DecorationLetter from "../Decorations/DecorationLetter";
import DecorationSquare from "../Decorations/DecorationSquare";

import "./InfoHeader.scss";

const InfoHeader = ({
  currentProgram,
  currentSection,
  isNextButton,
  textLinkBack,
  urlLinkNext,
  textLinkNext,
  urlLinkBack,
}) => {
  const getColor =
    currentProgram.content &&
    currentProgram.content.program_background_color.color;

  return (
    <section style={{ backgroundColor: getColor }} className="info-header">
      <div className="info-header__frame">
        <div className="info-header__holder">
          <div className="info-header__col-text">
            <div className="info-header__link-back">
              <LinkBack
                text={textLinkBack}
                color={"white"}
                to={urlLinkBack}
              />
            </div>

            <div className="info-header__description">
              <strong className="h6">
                {has(currentProgram, "content.program_subtitle") &&
                  `${currentProgram.content.program_subtitle}`}

                {has(currentProgram, "content.program_title") &&
                  ` / ${currentProgram.content.program_title}`}
              </strong>

              {has(currentSection, "content.section_title") && (
                <h2>{currentSection.content.section_title}</h2>
              )}

              <div className="info-header__content">
                {currentSection.content &&
                  currentSection.content.section_description}
              </div>

              {isNextButton && (
                <LinkDouble href={urlLinkNext} text={textLinkNext} />
              )}
            </div>
          </div>

          <div className="info-header__col-image">
            <div className="info-header__col-image-holder">
              <picture>
                <source
                  className="title-img"
                  srcSet={
                    currentSection.content &&
                    currentSection.content.section_image
                  }
                  media="(min-width: 768px)"
                />
                <img
                  src={
                    currentSection.content &&
                    currentSection.content.section_image_mobile
                  }
                  alt={currentSection.name}
                />
              </picture>
              <DecorationLetter color={getColor} size={120} />
              <DecorationSquare color={getColor} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

InfoHeader.propTypes = {
  currentProgram: PropTypes.object,
  currentSection: PropTypes.object,
  isNextButton: PropTypes.bool,
  textLinkBack: PropTypes.string,
  urlLinkNext: PropTypes.string,
  textLinkNext: PropTypes.string,
  urlLinkBack: PropTypes.string,
};

export default InfoHeader;
