import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import "./LinkDefault.scss";

const LinkDefault = ({ text, href, isWhiteBorder, isBlank }) => {
  const btnClass = classNames({
    "link-default": true,
    "has-white-border": isWhiteBorder,
  });

  return (
    /* eslint-disable-next-line react/jsx-no-target-blank */ // Disabled, the rule isn't violated
    <a
      href={href}
      target={isBlank ? "_blank" : null}
      rel={isBlank ? "noopener noreferrer" : null}
      className={btnClass}
    >
      <span className="text">{text}</span>
    </a>
  );
};

LinkDefault.defaultProps = {
  isWhiteBorder: false,
  isBlank: false,
};

LinkDefault.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  isBlank: PropTypes.bool,
  isWhiteBorder: PropTypes.bool,
};

export default LinkDefault;
