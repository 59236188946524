import React from "react";
import PropTypes from "prop-types";
import RichTextImage from "../RichTextImage";
import ArticleBlurb from "../ArticleBlurb";
import "./MixedContent.scss";

const MixedContent = ({ data }) => {
  const components = data.content.map((item) => {
    switch (item.component) {
      case "article_blurb":
        return <ArticleBlurb key={item._uid} data={item} />;
      case "rich_text_image":
        return <RichTextImage key={item._uid} data={item} />;
      default:
        return <p>Invalid Component</p>;
    }
  });
  return <div className="MixedContent">{components}</div>;
};

MixedContent.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array,
  }),
};

export default MixedContent;
