import React from "react";
import PropTypes from "prop-types";

import LinkBack from "../Links/LinkBack";
import DecorationSquare from "../Decorations/DecorationSquare";
import getEventDate from "../../utils/get-event-date";

import styles from "./ArticlePromo.scss";

const AllNewsArticleTitle = ({ data }) => {
  const isEventsView = data.component === "events_item";
  const subtitles = {
    announcement_item: "ANNOUNCEMENT",
    news_item: "Competition",
    events_item: "Events",
    success_story: "SUCCESS STORY",
  };
  const subtitle = subtitles[data.component] || data.subtitle;
  let getEventStartData = null;

  if (isEventsView) {
    getEventStartData = data.item_date ? data.item_date : null;
  }

  return (
    <section className="article-promo">
      <div className="article-promo__wrapper">
        <div className="article-promo__content">
          <div className="article-promo__back-holder">
            <LinkBack text={"Back"} />
          </div>

          <strong className="article-promo__subtitle">{subtitle}</strong>

          <h2>{data.item_title}</h2>

          {isEventsView && (
            <div className="article-promo__meta-holder">
              {getEventStartData && (
                <time>{getEventDate(getEventStartData)}</time>
              )}
              {data.item_location && (
                <span className="location">{data.item_location}</span>
              )}
            </div>
          )}

          <div className="article-promo__description">
            {data.item_description}
          </div>
        </div>

        <div className="article-promo__visual">
          <DecorationSquare color={styles["green-300"]} />
          <img src={data.page_image} alt={data.item_title} />
        </div>
      </div>

      <div className="decor-line" />
    </section>
  );
};

AllNewsArticleTitle.propTypes = {
  data: PropTypes.object,
};

export default AllNewsArticleTitle;
