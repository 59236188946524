import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Markdown from "markdown-to-jsx";

import useWindowDimensions from "../../hooks/windowViewPort";
import getParallaxData from "../../utils/get-parallax-data";

import DecorationSquare from "../Decorations/DecorationSquare";

import "./_SectionTitleBlock.scss";

gsap.registerPlugin(ScrollTrigger);

const SectionTitleBlock = ({ data, props }) => {
  const isWithImage =
    props?.img || props?.childImage || (data.image && data.image.id);

  const imgDecorationRef = useRef(null);

  const parallaxData = getParallaxData(
    props?.className,
    useWindowDimensions("width"),
  );

  useEffect(() => {
    gsap.fromTo(imgDecorationRef.current, ...parallaxData, []);
  });

  return (
    <section className={classNames(["section-title-block", props?.className])}>
      <div className="section-title__wrapper">
        <section
          className={classNames(["section-title", !isWithImage && "no-image"])}
        >
          <h1>{data.title}</h1>
          <Markdown options={{ forceBlock: true }}>{data.subtitle}</Markdown>
          {!data?.numbers?.fieldtype && (
            <div className="title-note">
              <b>{data.numbers}</b>
              <span>{data.text_note}</span>
            </div>
          )}
        </section>
        {isWithImage && (
          <div className={`image__wrapper${data.large_image ? "--large" : ""}`}>
            {data.image && data.image.id && (
              <DecorationSquare color="#ff005d" />
            )}
            {(props?.img || (data.image && data.image.id)) && (
              <img
                className={"main-img"}
                src={props?.img || data.image.filename}
                alt={data.title}
                ref={imgDecorationRef}
              />
            )}
            {props?.childImage && (
              <img
                className="child-img"
                src={props?.childImage}
                alt={data.title}
              />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

SectionTitleBlock.propTypes = {
  data: PropTypes.object,
  props: PropTypes.object,
  className: PropTypes.string,
  img: PropTypes.string,
  childImage: PropTypes.string,
};

export default SectionTitleBlock;
