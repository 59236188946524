import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  faFacebookSquare,
  faYoutube,
  faTwitterSquare,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import "./_SocialNetworks.scss";

const SocialNetworks = ({
  closeMainMenu,
  showIcons,
  showText,
  isCustomURLs,
}) => {
  const socialNetworks = [
    {
      text: "Facebook",
      link: isCustomURLs
        ? showIcons.facebook
        : "https://www.facebook.com/apprenticeshipireland",
      icon: <FontAwesomeIcon icon={faFacebookSquare} />,
      isShow: showIcons.facebook,
      isShowText: showText,
    },
    {
      text: "Youtube",
      link: isCustomURLs
        ? showIcons.youtube
        : "https://www.youtube.com/channel/UC-GrLjT3i9pkw7BPJVWdCzQ",
      icon: <FontAwesomeIcon icon={faYoutube} />,
      isShow: showIcons.youtube,
      isShowText: showText,
    },
    {
      text: "Twitter",
      link: isCustomURLs
        ? showIcons.twitter
        : "https://twitter.com/apprenticesIrl",
      icon: <FontAwesomeIcon icon={faTwitterSquare} />,
      isShow: showIcons.twitter,
      isShowText: showText,
    },
    {
      text: "Linkedin",
      link: isCustomURLs
        ? showIcons.linkedin
        : "https://www.linkedin.com/company/apprenticeship-ireland/",
      icon: <FontAwesomeIcon icon={faLinkedin} />,
      isShow: showIcons.linkedin,
      isShowText: showText,
    },
    {
      text: "Instagram",
      link: isCustomURLs
        ? showIcons.instagram
        : "https://www.instagram.com/apprenticesirl/",
      icon: <FontAwesomeIcon icon={faInstagram} />,
      isShow: showIcons.instagram,
      isShowText: showText,
    },
  ];

  return (
    <ul className="social-networks__list">
      {socialNetworks.map(
        (props, index) =>
          props.isShow && (
            <li key={index}>
              <a
                href={props.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMainMenu}
              >
                {props.icon}

                <span
                  className={
                    props.isShowText ? "social-title" : "visually-hidden"
                  }
                >
                  {props.text}
                </span>
              </a>
            </li>
          ),
      )}
    </ul>
  );
};

SocialNetworks.defaultProps = {
  closeMainMenu: null,
  showText: false,
  isCustomURLs: false,
};

SocialNetworks.propTypes = {
  closeMainMenu: PropTypes.func,
  showIcons: PropTypes.object,
  showText: PropTypes.bool,
  isShow: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.string,
  isShowText: PropTypes.string,
  text: PropTypes.string,
  isCustomURLs: PropTypes.bool,
};

export default SocialNetworks;
