import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import "./Loader.scss";

const Loader = ({ isSmall, isHolder }) => {
  const mainClass = classNames({
    loader: true,
    "small-size": isSmall,
  });

  const renderContent = (
    <div className={mainClass}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  return isHolder ? (
    <div className="main-loader">{renderContent}</div>
  ) : (
    { renderContent }
  );
};

Loader.propTypes = {
  isSmall: PropTypes.bool,
  isHolder: PropTypes.bool,
};

export default Loader;
