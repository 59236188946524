import React from "react";
import PropTypes from "prop-types";

import { PageConsumer } from "../Contexts/PageContext";

import ExploreOurProgrammesSection from "../ExploreOurProgrammesSection";

import "./_ExploreOurProgrammesList.scss";

const ExploreOurProgrammesList = ({
  data,
  component,
  sectionData,
  isExpanded,
}) => {
  return (
    <PageConsumer>
      {(pageName) => {
        const getSortProgrammes = data.programmes_blocks.sort(
          (firstEl, secondEl) => {
            const firstName = firstEl.name.toLowerCase();
            const secondName = secondEl.name.toLowerCase();
            if (firstName < secondName) {
              return -1;
            }
            if (firstName > secondName) {
              return 1;
            }
            return 0;
          },
        );
        return (
          <div className="explore-programmes">
            {getSortProgrammes.map((section) => {
              return (
                <ExploreOurProgrammesSection
                  key={section.name}
                  section={section}
                  sectionData={sectionData}
                  pageName={pageName}
                  component={component}
                  isExpanded={isExpanded}
                />
              );
            })}
          </div>
        );
      }}
    </PageConsumer>
  );
};

ExploreOurProgrammesList.propTypes = {
  data: PropTypes.object,
  sectionData: PropTypes.array,
  component: PropTypes.string,
  isExpanded: PropTypes.bool,
};

export default ExploreOurProgrammesList;
