import React from "react";
import PropTypes from "prop-types";

import "./_GetInvolved.scss";

const GetInvolved = ({ data }) => {
  return (
    <>
      <div className="page-section_head">
        <div className="page-section_head__holder">
          <h2>{data.title}</h2>
          {(data.link.url || data.link.cached_url) && (
            <a href={data.link.url || data.link.cached_url}>{data.link_copy}</a>
          )}
        </div>
      </div>
      <ul className="get-involved">
        {data.blocks.map((item) => (
          <li key={item.content.title}>
            <img src={item.content.image} alt={item.content.title} />
            <section className="text-group">
              <strong>{item.content.title}</strong>
              <a
                href={
                  item.content.link.url || `/${item.content.link.cached_url}/`
                }
              >
                {item.content.link_name}
                <span>⟶</span>
              </a>
            </section>
          </li>
        ))}
      </ul>
    </>
  );
};

GetInvolved.propTypes = {
  data: PropTypes.object,
};

export default GetInvolved;
