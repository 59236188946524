import React from "react";

import SectionTitleMenu from "../../components/SectionTitleMenu";
import MoreSectionMenuConfig from "../../config/MoreSectionMenuConfig";
import SkewTopFooter from "../../components/Skews/SkewTopFooter";
import CMSPageData from "../../components/CMSPageData";

export default () => (
  <>
    <SectionTitleMenu menuList={MoreSectionMenuConfig} />
    <CMSPageData pageName="yourLocalProvider" />
    <SkewTopFooter />
  </>
);
