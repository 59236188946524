export default [
  {
    id: "instructors",
    link: "/instructors/introduction",
    text: "Instructor Careers",
    isSubmenu: false,
    isActive: false,
  },
  {
    id: "career-seekers",
    link: "/career-seekers/introduction",
    text: "Career Seekers",
    isSubmenu: false,
    isActive: false,
  },
  {
    id: "career-advisors",
    link: "/career-advisors/educators",
    text: "Career Advisors",
    isSubmenu: false,
    isActive: false,
  },
  {
    id: "employers",
    link: "/employers/potential-employers",
    text: "Employers",
    isSubmenu: false,
    isActive: false,
  },
  {
    id: "news-events",
    link: "/news-events/news",
    text: "News / Events",
    isSubmenu: false,
    isActive: false,
  },
  {
    id: "more",
    link: "",
    text: "More",
    isSubmenu: true,
    isActive: false,
  },
];
