import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { animateScroll as scroll } from "react-scroll";

import { richTextResolver } from "../../CMS/Storyblok";
import styles from "./_ContentWithSideTabs.scss";

const ContentWithSideTabs = ({ data }) => {
  const [currentItemID, setCurrentItemID] = useState(data.blocks[0].uuid);
  const [content, setContent] = useState({});

  useEffect(() => {
    const currentItem = data.blocks.filter(
      (item) => item.uuid === currentItemID,
    );
    setContent(currentItem[0].content.content);
  }, [currentItemID, data.blocks]);

  const handleOnClickNavigation = (item) => () => {
    setCurrentItemID(item.uuid);
    scroll.scrollToTop();
  };

  return (
    <>
      <div className="two-columns about-page-padding">
        <div className="two-columns__content">
          <section className="get-started-description">
            <div
              className="get-started-description__holder"
              dangerouslySetInnerHTML={{ __html: richTextResolver(content) }}
            />
          </section>
        </div>
        <div className="two-columns__aside">
          <section className="nav-sidebar">
            {data.blocks.length > 1 && (
              <>
                <h2>{data.sidebar_title}</h2>
                <ul className="nav-sidebar__list">
                  {data.blocks.map((item) => (
                    <li
                      className={
                        item.uuid === currentItemID ? "active-item" : null
                      }
                      key={item.uuid}
                    >
                      <button
                        onClick={handleOnClickNavigation(item)}
                        style={{ backgroundColor: styles.electrical }}
                      >
                        <span className="text">{item.content.link_copy}</span>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

ContentWithSideTabs.propTypes = {
  data: PropTypes.object,
};

export default ContentWithSideTabs;
