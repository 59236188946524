import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

function SEO({ title, image, url, description }) {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta itemProp="description" content={description} />
      <meta name="description" content={description} />
      <meta name="twitter:site" content="Generation Apprenticeship" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="Generation Apprenticeship" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Generation Apprenticeship" />
      <meta property="og:image:width" content="293" />
      <meta property="og:image:height" content="144" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta itemProp="name" content="Generation Apprenticeship" />
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />
      <meta itemProp="image" content={image} />
      <meta property="og:image" content={image} />
      <meta property="og:image:url" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta name="twitter:image:src" content={image} />
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
};

export default SEO;
