import React from "react";

import SectionTitleMenu from "../../components/SectionTitleMenu";
import MoreSectionMenuConfig from "../../config/MoreSectionMenuConfig";
import CMSPageData from "../../components/CMSPageData";
import SkewTopFooter from "../../components/Skews/SkewTopFooter";

export default () => (
  <div className="page-apprenticeship-directory">
    <SectionTitleMenu menuList={MoreSectionMenuConfig} />
    <CMSPageData pageName="apprenticeshipDirectory" />
    <SkewTopFooter />
  </div>
);
