import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import "./ButtonIcon.scss";

function Button({ className, buttonChildren, iconChildren, onClick }) {
  return (
    <div className={classNames(["ButtonIcon", className])} onClick={onClick}>
      <div className="ButtonIcon__container">
        <button className="ButtonIcon__icon">{iconChildren}</button>
        <button className="ButtonIcon__button">{buttonChildren}</button>
      </div>
    </div>
  );
}
Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  buttonChildren: PropTypes.any,
  iconChildren: PropTypes.any,
};

export default Button;
