import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "./Pagination.scss";

const paginationSizes = [10, 25, 50, 100];

const usePagination = (itemsCount = 0, totalItems = 0, options = {}) => {
  const { initialPage = 0, initialPageSize = 10, onPaginationChange } = options;

  const normalizePage = (page, pageSize) => {
    const totalPages = Math.ceil(totalItems / pageSize);
    return page >= totalPages ? totalPages - 1 : page;
  };
  const normalizePageSize = (ps) => {
    const minPageSize = Math.min(...paginationSizes);
    const maxPageSize = Math.max(...paginationSizes);

    if (ps > maxPageSize) return maxPageSize;
    if (ps < minPageSize) return minPageSize;

    return ps;
  };

  const [page, setPageState] = useState(initialPage);
  const [pageSize, setPageSizeState] = useState(initialPageSize);

  const helpers = {
    setPage: (p) => setPageState(normalizePage(p)),
    setPageSize: (ps) => setPageSizeState(normalizePageSize(ps)),
  };

  const PreconfiguredComponent = (props) => (
    <Pagination
      itemsCount={itemsCount}
      totalItems={totalItems}
      page={page}
      pageSize={pageSize}
      onPaginationChange={(event) => {
        onPaginationChange({
          oldPage: page,
          newPage: event.page,
          oldPageSize: pageSize,
          newPageSize: event.pageSize,
        });
        setPageState(normalizePage(event.page, event.pageSize));
        setPageSizeState(normalizePageSize(event.pageSize));
      }}
      {...props}
    />
  );

  return [PreconfiguredComponent, page, pageSize, helpers];
};

const Pagination = ({
  page,
  pageSize,
  itemsCount,
  totalItems,
  onPaginationChange,
  itemsLabel,
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);
  return (
    <div className="pagination">
      <span>
        Showing{" "}
        <Listbox
          className="pagination__listbox"
          as="span"
          value={pageSize}
          onChange={(ps) => onPaginationChange({ page, pageSize: ps })}
        >
          <Listbox.Button className="pagination__listbox-button">
            {({ open }) => (
              <>
                <span>{itemsCount}</span>
                <span className="pagination__listbox-button-icon">
                  {open ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </span>
              </>
            )}
          </Listbox.Button>
          <Listbox.Options className="pagination__listbox-options">
            {paginationSizes.map((ps) => (
              <Listbox.Option key={ps} value={ps}>
                {ps}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>{" "}
        of {totalItems} {itemsLabel}
      </span>

      <div className="pagination__wrapper">
        <p>Pages:</p>

        <button
          disabled={totalPages <= 1 || page <= 0}
          className={`pagination__button pagination__icon ${
            totalPages <= 1 || page === 0 ? "pagination__button--disabled" : ""
          }`}
          onClick={() =>
            onPaginationChange({
              page: page - 1,
              pageSize,
            })
          }
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {Array.from(Array(totalPages).keys()).map((i) => (
          <button
            key={i}
            onClick={() =>
              onPaginationChange({
                page: i,
                pageSize,
              })
            }
            className={
              page === i ? "pagination__button--active" : "pagination__button"
            }
          >
            {i + 1}
          </button>
        ))}

        <button
          disabled={totalPages <= 1 || page >= totalPages - 1}
          className={`pagination__button pagination__icon ${
            totalPages <= 1 || page === totalPages - 1
              ? "pagination__button--disabled"
              : ""
          }`}
          onClick={() =>
            onPaginationChange({
              page: page + 1,
              pageSize,
            })
          }
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};
Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  itemsLabel: PropTypes.string,
};

Pagination.defaultProps = {
  itemsLabel: "Jobs",
};

export { usePagination };
export default Pagination;
