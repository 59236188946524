import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./LinkDouble.scss";

const LinkDouble = ({ text, href, handleOnClick }) => {
  return (
    <Link to={href} className="link-double" onClick={handleOnClick}>
      <span className="link-double__holder">{text}</span>
    </Link>
  );
};

LinkDouble.defaultProps = {
  handleOnClick: null,
};

LinkDouble.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  handleOnClick: PropTypes.func,
};

export default LinkDouble;
