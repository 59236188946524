import {
  faCompass,
  faEye,
  faEyeSlash,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import distance from "@turf/distance";
import { point } from "@turf/helpers";
import classNames from "classnames/bind";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import React, { useEffect, useReducer, useRef, useState } from "react";
import useWindowDimensions from "../../hooks/windowViewPort";
import ButtonIcon from "../ButtonIcon";
import Marker from "../Marker";
import "./LocalProvidersMaps.scss";
import ProviderCard from "./ProviderCard";

const actions = {
  recenterMap: "recenterMap",
  setUserLocation: "setUserLocation",
  selectLocation: "selectLocation",
  resetLocation: "resetLocation",
  clearLocationInput: "clearLocationInput",
  showYourLocation: "showYourLocation",
  hideYourLocationInfobox: "hideYourLocationInfobox",
  toggleMapVisibility: "toggleMapVisibility",
};

const LocalProviders = ({ data }) => {
  const dublinCoords = { lat: 53.3498053, lng: -6.2603097 };

  const locationInputRef = useRef();
  const [selectedProvider, setSelectedProvider] = useState();
  const [clearIconVisible, setClearIconVisible] = useState(false);
  const googleApi = useRef();
  const mapRef = useRef();
  const initMapMonitor = useRef(); // ensures execution of code after map is initialized
  const screenWidth = useWindowDimensions("width");

  const [
    {
      selectedLocationCoords,
      selectedLocationText,
      mapCenter,
      defaultLocationCoords,
      resetButtonVisible,
      locationFoundInfoboxVisible,
      mapVisible,
      userLocationFound,
    },
    dispatch,
  ] = useReducer(
    (curVals, action) => {
      switch (action.type) {
        case actions.hideYourLocationInfobox:
          return {
            ...curVals,
            locationFoundInfoboxVisible: false,
          };
        case actions.showYourLocation:
          mapRef.current.setCenter(curVals.defaultLocationCoords);
          return {
            ...curVals,
            locationFoundInfoboxVisible: true,
            mapCenter: curVals.defaultLocationCoords,
          };
        case actions.recenterMap:
          mapRef.current.setCenter(action.coords);
          return {
            ...curVals,
            mapCenter: action.coords,
          };
        case actions.setUserLocation:
          mapRef.current.setCenter(action.coords);
          return {
            ...curVals,
            userLocationFound: true,
            defaultLocationCoords: action.coords,
            defaultLocationText: "Your Location",
            selectedLocationCoords: action.coords,
            selectedLocationText: "Your Location",
            mapCenter: action.coords,
          };
        case actions.selectLocation:
          return {
            ...curVals,
            resetButtonVisible: true,
            selectedLocationCoords: action.coords,
            selectedLocationText: action.text,
            mapCenter: action.coords,
          };
        case actions.resetLocation:
          setClearIconVisible(false);
          locationInputRef.current.value = "";
          return {
            ...curVals,
            resetButtonVisible: false,
            selectedLocationCoords: curVals.defaultLocationCoords,
            selectedLocationText: curVals.defaultLocationText,
            mapCenter: curVals.defaultLocationCoords,
          };
        case actions.clearLocationInput:
          setClearIconVisible(false);
          locationInputRef.current.value = "";
          return { ...curVals };
        case actions.toggleMapVisibility:
          return {
            ...curVals,
            mapVisible: !curVals.mapVisible,
          };
        default:
          return { ...curVals };
      }
    },
    {
      defaultLocationCoords: dublinCoords,
      defaultLocationText: "Dublin",
      mapCenter: dublinCoords,
      selectedLocationCoords: dublinCoords,
      selectedLocationText: "Dublin",
      resetButtonVisible: false,
      locationFoundInfoboxVisible: false,
      mapVisible: true,
      userLocationFound: false,
    },
  );

  // override default location to user's position (if he consents)
  useEffect(() => {
    if (!navigator.geolocation) {
      return;
    }

    // TODO maybe it's something else -> I always got position first and map initialized second. Could it be just a wrong order of set states?
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        if (mapRef.current) {
          // map is initialized already

          // geocoding code backed up for future - we can get textual representation of user's current location with this
          // const geocoder = new googleApi.current.Geocoder();
          // geocoder
          //   .geocode({ location: coords })
          //   .then((response) => console.log(response));

          dispatch({
            type: actions.setUserLocation,
            coords,
          });
        } else {
          // save coords for when map is initialized later
          initMapMonitor.current = coords;
        }
      },
      () => {
        // eslint-disable-next-line no-console
        console.error("User position not obtained.");
      },
    );
  }, []);

  const coordsValid = (coords) =>
    !Number.isNaN(coords.lat) && !Number.isNaN(coords.lng);

  const distanceToLocation = (coords) => {
    const coordsPoint = point([coords.lng, coords.lat]);
    const locationPoint = point([
      selectedLocationCoords.lng,
      selectedLocationCoords.lat,
    ]);
    return distance(coordsPoint, locationPoint);
  };

  const providersByProximity = data.blocks_providers.sort((p1, p2) => {
    const coords1 = {
      lat: Number(p1.content.latitude),
      lng: Number(p1.content.longitude),
    };
    const coords2 = {
      lat: Number(p2.content.latitude),
      lng: Number(p2.content.longitude),
    };
    if (!coordsValid(coords1) && !coordsValid(coords2))
      return p1.content.name.localeCompare(p2.content.name);
    if (!coordsValid(coords1)) return 1;
    if (!coordsValid(coords2)) return -1;

    const d1 = distanceToLocation(coords1);
    const d2 = distanceToLocation(coords2);
    return d1 - d2;
  });

  // we need to add a listener in the onGoogleApiLoaded, so we need to store reference to it here and remove the listener on unmounting the component
  const listenerRef = useRef();
  useEffect(() => {
    return () => {
      if (listenerRef.current) listenerRef.current.remove();
    };
  }, []);

  return (
    <div className="LocalProvidersMaps">
      <div className="LocalProvidersMaps__header">
        <div className="LocalProvidersMaps__header-title">
          <h4>Local Apprenticeship Providers</h4>
        </div>

        <div className="LocalProvidersMaps__header-filter">
          <div className="LocalProvidersMaps__header-filter-space" />{" "}
          {/* placeholder for empty space */}
          <div className="LocalProvidersMaps__header-filter-input">
            <FontAwesomeIcon icon={faCompass} />
            <input
              type="text"
              ref={locationInputRef}
              placeholder="Search Location"
              onChange={(event) => {
                const text = event.target.value;
                setClearIconVisible(text.length > 0);
              }}
            />
            {clearIconVisible && (
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => dispatch({ type: actions.clearLocationInput })}
              />
            )}
          </div>
          {resetButtonVisible ? (
            <button
              className="LocalProvidersMaps__header-filter-reset"
              onClick={() => dispatch({ type: actions.resetLocation })}
            >
              Reset
            </button>
          ) : (
            // placeholder for empty space
            <div className="LocalProvidersMaps__header-filter-space" />
          )}
        </div>
      </div>

      <div className="LocalProvidersMaps__content">
        <div className="LocalProvidersMaps__providers">
          <div className="LocalProvidersMaps__providers-title">
            Showing Providers by distance from{" "}
            <strong>{selectedLocationText}</strong>
          </div>
          {providersByProximity.map((provider) => {
            const coords = {
              lat: Number(provider.content.latitude),
              lng: Number(provider.content.longitude),
            };

            return (
              <ProviderCard
                key={provider.content._uid}
                onClick={() => {
                  setSelectedProvider(provider.content._uid);
                  if (coordsValid(coords))
                    dispatch({ type: actions.recenterMap, coords });
                }}
                distanceText={
                  coordsValid(coords)
                    ? `${Math.round(distanceToLocation(coords))} km`
                    : "? km"
                }
                selected={selectedProvider === provider.content._uid}
                provider={provider.content}
              />
            );
          })}
        </div>

        <div
          className={classNames(
            "LocalProvidersMaps__google-maps-wrapper",
            mapVisible || "map-hidden",
          )}
        >
          {userLocationFound && mapVisible && (
            <button
              className="LocalProvidersMaps__center-button"
              onClick={() => dispatch({ type: actions.showYourLocation })}
            >
              Show Your Location
            </button>
          )}
          <ButtonIcon
            className="LocalProvidersMaps__toggle-visibility-button"
            buttonChildren={mapVisible ? "Hide Map" : "Show Map"}
            iconChildren={
              mapVisible ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )
            }
            onClick={() => dispatch({ type: actions.toggleMapVisibility })}
          />
          {mapVisible && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCLCccXlEGycK9N1sym2ItxpakmDTRvOp0",
                libraries: ["places"],
              }}
              center={mapCenter}
              defaultZoom={11}
              options={{
                gestureHandling: screenWidth > 1040 ? "greedy" : "auto",
                disableDefaultUI: screenWidth <= 1040, // UI buttons show up on desktop only
              }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                googleApi.current = maps;
                mapRef.current = map;

                if (initMapMonitor.current) {
                  // location was obtained already, change default location

                  // geocoding code backed up for future - we can get textual representation of user's current location with this
                  // const geocoder = new maps.Geocoder();
                  // geocoder
                  //   .geocode({ location: initMapMonitor.current })
                  //   .then((response) => console.log(response));

                  dispatch({
                    type: actions.setUserLocation,
                    coords: initMapMonitor.current,
                  });
                }

                const options = {
                  // bounds: defaultBounds,
                  componentRestrictions: { country: "ie" },
                  fields: ["formatted_address", "geometry"],
                  // strictBounds: false,
                  types: ["geocode"],
                };
                const autocomplete = new maps.places.Autocomplete(
                  locationInputRef.current,
                  options,
                );

                listenerRef.current = autocomplete.addListener(
                  "place_changed",
                  () => {
                    const selectedPlace = autocomplete.getPlace();
                    const coords = {
                      lat: selectedPlace.geometry.location.lat(),
                      lng: selectedPlace.geometry.location.lng(),
                    };
                    dispatch({
                      type: actions.selectLocation,
                      text: selectedPlace.formatted_address,
                      coords,
                    });
                  },
                );
              }}
            >
              {locationFoundInfoboxVisible && (
                <YourPositionInfobox
                  lat={defaultLocationCoords.lat}
                  lng={defaultLocationCoords.lng}
                  onClick={() =>
                    dispatch({ type: actions.hideYourLocationInfobox })
                  }
                />
              )}
              {data.blocks_providers.map((provider) => {
                const coords = {
                  lat: Number(provider.content.latitude),
                  lng: Number(provider.content.longitude),
                };
                if (!coordsValid(coords)) {
                  return null;
                }
                return (
                  <Marker
                    key={provider.content._uid}
                    onClick={() => {
                      if (selectedProvider !== provider.content._uid)
                        setSelectedProvider(provider.content._uid);
                      else setSelectedProvider(undefined);
                    }}
                    selected={selectedProvider === provider.content._uid}
                    provider={provider.content}
                    lat={provider.content.latitude}
                    lng={provider.content.longitude}
                  />
                );
              })}
            </GoogleMapReact>
          )}
        </div>
      </div>
    </div>
  );
};
LocalProviders.propTypes = {
  data: PropTypes.object,
};

const YourPositionInfobox = ({ lat, lng, onClick }) => {
  return (
    <div
      className="LocalProvidersMaps__your-position-infobox"
      lat={lat}
      lng={lng}
    >
      <div className="LocalProvidersMaps__your-position-infobox-container">
        <FontAwesomeIcon
          className="LocalProvidersMaps__your-position-infobox-close-icon"
          icon={faTimes}
          onClick={onClick}
        />
        <div className="LocalProvidersMaps__your-position-infobox-content">
          Location found
        </div>
      </div>
      <div className="LocalProvidersMaps__your-position-infobox-triangle" />
    </div>
  );
};
YourPositionInfobox.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  onClick: PropTypes.func,
};

export default LocalProviders;
