import React from "react";
import PropTypes from "prop-types";

import "./DecorationSquare.scss";

const DecorationSquare = ({ color, size }) => {
  const styles = {
    background: `repeating-linear-gradient(-45deg,
      ${color}, ${color} 1px, transparent 1.5px, transparent ${
      size ? "4px" : "7px"
    })`,
    width: size ? `${size}px` : null,
    height: size ? `${size}px` : null,
  };
  return <span style={styles} className="decoration-square" />;
};

DecorationSquare.defaultProps = {
  size: null,
};

DecorationSquare.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default DecorationSquare;
