import {
  faMapMarkerAlt,
  faRoute,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react";
import "./Marker.scss";

const Marker = ({ provider, onClick, selected }) => {
  return (
    <div className={classNames({ Marker: true, selected })}>
      {selected && (
        <div className="Marker__infobox">
          <div className="Marker__infobox-container">
            <FontAwesomeIcon
              className="Marker__infobox-close-icon"
              icon={faTimes}
              onClick={onClick}
            />
            <div className="Marker__infobox-title">{provider.name}</div>
            <div className="Marker__infobox-content">{provider.address}</div>
            <a
              className="Marker__infobox-maps-link"
              href={provider.link_to_maps}
              rel="noreferrer"
              target="_blank"
            >
              Get Directions <FontAwesomeIcon icon={faRoute} />
            </a>
          </div>
          <div className="Marker__infobox-triangle" />
        </div>
      )}
      <FontAwesomeIcon
        size="3x"
        icon={faMapMarkerAlt}
        className="Marker__icon"
        onClick={onClick}
      />
    </div>
  );
};

Marker.propTypes = {
  provider: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    link_to_maps: PropTypes.string,
  }),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default Marker;
