import React from "react";
import PropTypes from "prop-types";
import LinkDefault from "../../Links/LinkDefault";
import { richTextResolver } from "../../../CMS/Storyblok";

import "./ArticleBlurb.scss";

const ArticleBlurb = ({ data }) => {
  return (
    <section className="ArticleBlurb">
      <h1>{data.title}</h1>
      <div
        className="ArticleBlurb__blurb"
        dangerouslySetInnerHTML={{ __html: richTextResolver(data.text) }}
      />
      {data.button_link && (
        <div className="ArticleBlurb__button">
          <LinkDefault
            text={data.link_text}
            isWhiteBorder={false}
            href={data.read_more_link.url || data.read_more_link.cached_url}
            isBlank={false}
          />
        </div>
      )}
    </section>
  );
};

ArticleBlurb.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.object,
    button_link: PropTypes.bool,
    read_more_link: PropTypes.object,
    link_text: PropTypes.string,
  }),
};

export default ArticleBlurb;
