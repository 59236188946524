import React, { useState } from "react";
import PropTypes from "prop-types";
import AmbassadorCard from "../AmbassadorCard";
import LightBoxGallery from "../LightBoxGallery";
import "./PhotoGallery.scss";

const PhotoGallery = ({ data }) => {
  const [lightGalleryIsOpen, setLightGalleryIsOpen] = useState(false);
  const [displayImageID, setDisplayImageID] = useState(0);
  const onCardClickHandler = (arrayId) => {
    setDisplayImageID(arrayId);
    setLightGalleryIsOpen(true);
  };

  const closeLightBoxGalleryHandler = () => {
    setLightGalleryIsOpen(false);
  };
  return (
    <div className="PhotoGallery">
      {data.title && <h1 className="PhotoGallery__title">{data.title}</h1>}
      <div className="PhotoGallery__gallery">
        {data.Photos.map((item, key) => {
          return (
            <AmbassadorCard
              key={key}
              itemNo={key}
              title={item.title}
              image={item.image}
              name={item.name}
              company={item.company}
              onClick={onCardClickHandler}
            />
          );
        })}
      </div>
      {lightGalleryIsOpen && (
        <LightBoxGallery
          initialDisplayID={displayImageID}
          handleCloseLightBoxGallery={closeLightBoxGalleryHandler}
          images={data.Photos}
        />
      )}
    </div>
  );
};

PhotoGallery.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    Photos: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        name: PropTypes.string,
        company: PropTypes.string,
        image: PropTypes.string,
      }),
    ),
  }),
};

export default PhotoGallery;
