import React from "react";

import CareerSeekersSectionMenuConfig from "../../config/CareerSeekersSectionMenuConfig";
import SectionTitleMenu from "../../components/SectionTitleMenu/SectionTitleMenu.jsx";
import CMSPageData from "../../components/CMSPageData";

import "./AlreadyAnApprenticePage.scss";

export default () => {
  return (
    <>
      <SectionTitleMenu menuList={CareerSeekersSectionMenuConfig} />
      <div className="already-an-apprentice-page">
        <CMSPageData pageName="alreadyAnApprenticePage" />
      </div>
    </>
  );
};
