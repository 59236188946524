import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useWindowDimensions from "../../hooks/windowViewPort";

import MainMenuConfig from "../../config/MainMenuConfig";
import MainMenuSubRoutesConfig from "../../config/MainMenuSubRoutesConfig";

import MainMenu from "../MainMenu";
import AsideMainMenu from "../AsideMainMenu/AsideMainMenu.jsx";

import "./_Header.scss";

const Header = () => {
  /* State to looking is main menu open or closed */
  const [mainMenu, setMainMenu] = useState({ isMainMenuOpen: false });

  /* State to looking is Submenu menu open or closed */
  const [showSubmenu, setShowSubmenu] = useState({ isShowSubMenu: false });

  /* State to looking is Second Submenu menu open or closed (Mobile && Tablets only) */
  const [showSecondMobileSubMenu, setShowSecondMobileSubMenu] = useState({
    isShowSecondSubMenu: false,
  });

  /* Fn to opens/closes main menu with save current scroll position */
  const openCloseMainMenuHandler = () => {
    setMainMenu({ isMainMenuOpen: !mainMenu.isMainMenuOpen });
    setShowSecondMobileSubMenu({ isShowSecondSubMenu: false });
  };

  /* Fn to opens/closes subMenu */
  const openCloseSubMenuHandler = () => {
    setShowSubmenu({ isShowSubMenu: !showSubmenu.isShowSubMenu });
  };

  /* Fn to closes subMenu */
  const closeSubMenuHandler = () => {
    setShowSubmenu({ isShowSubMenu: false });
  };

  /* Fn to opens/closes second subMenu (Mobile && Tablets only) */
  const openCloseSecondSubMenuHandler = () => {
    setShowSecondMobileSubMenu({
      isShowSecondSubMenu: !showSecondMobileSubMenu.isShowSecondSubMenu,
    });
  };

  /* Main Routes List State\setState */
  const [mainMenuRoutesData, setMainMenuRoutesData] = useState(MainMenuConfig);

  /* Fn to sets no active Main Routes items */
  const setNoActiveMainRouts = () => {
    setMainMenuRoutesData(
      mainMenuRoutesData.map((el) => {
        return {
          id: el.id,
          link: el.link,
          text: el.text,
          isSubmenu: el.isSubmenu,
          isActive: false,
        };
      }),
    );
    if (mainMenu.isMainMenuOpen) {
      openCloseMainMenuHandler();
    }
  };

  useEffect(() => {
    if (window.location.pathname !== "/") {
      const currentUrl = window.location.pathname;

      mainMenuRoutesData.forEach((item) => {
        currentUrl.split("/").forEach((elem) => {
          if (item.id === elem && !item.isActive) {
            setMainMenuRoutesData(
              mainMenuRoutesData.map((el) => {
                return {
                  id: el.id,
                  link: el.link,
                  text: el.text,
                  isSubmenu: el.isSubmenu,
                  isActive: el.id === elem,
                };
              }),
            );
          }
        });
      });
    }
  });

  return (
    <>
      <header id="main-header" className="main-header">
        <nav className="main-header__nav">
          {useWindowDimensions("width") <= 1024 && (
            <button
              className={
                mainMenu.isMainMenuOpen
                  ? "burger-btn burger-open"
                  : "burger-btn"
              }
              aria-label="Opens or closes main menu"
              onClick={openCloseMainMenuHandler}
            >
              <i className="burger-btn__icon" />
              <span className="visually-hidden">Opens or Closes main menu</span>
            </button>
          )}

          <Link
            to="/"
            className="main-header__nav__logo"
            aria-label="Generation Apprenticeship logo"
            onClick={setNoActiveMainRouts}
          >
            <strong className="visually-hidden">
              Generation Apprenticeship logo
            </strong>
          </Link>

          {useWindowDimensions("width") > 1024 && (
            <MainMenu
              mainMenuRoutesData={mainMenuRoutesData}
              showSubmenu={showSubmenu}
              openCloseSubMenuHandler={openCloseSubMenuHandler}
              closeSubMenuHandler={closeSubMenuHandler}
              MainMenuSubRoutesConfig={MainMenuSubRoutesConfig}
            />
          )}
        </nav>
      </header>

      <AsideMainMenu
        mainMenu={mainMenu}
        openCloseMainMenuHandler={openCloseMainMenuHandler}
        mainMenuRoutes={MainMenuConfig}
        mainMenuSubRoutes={MainMenuSubRoutesConfig}
        showSecondMobileSubMenu={showSecondMobileSubMenu}
        openCloseSecondSubMenuHandler={openCloseSecondSubMenuHandler}
      />
    </>
  );
};

export default Header;
