import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as allComponents from "../../CMS/operations.jsx";
import ExploreOurProgrammesList from "../ExploreOurProgrammesList";
import NoteBlock from "../NoteBlock";
import ActionButton from "../static/ActionButton";

import {
  programmesLoadedAction,
  programSectionsLoadedAction,
} from "../../store/programmes/actions";

import "./explore-our-programmes.scss";
import formatAssetUrl from "../../utils/format-asset-url";

const ExploreOurProgrammes = ({
                                data,
                                programmesLoaded,
                                programSectionsLoaded,
                              }) => {
  const [sectionData, setSectionData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const isProgrammes = data.component === "programmes";
  const isCareerSeekersProgrammes =
    data.component === "career_seekers_programmes";
  const isEmployersProgrammes = data.component === "employers_programmes";

  useEffect(() => {
    if (isCareerSeekersProgrammes || isEmployersProgrammes) {
      programmesLoaded(data.programmes_blocks);

      const uuidsList =
        data &&
        data.programmes_blocks &&
        data.programmes_blocks
          .map((programme) => programme.content.program_section_blocks.map(
            (programSection) => typeof programSection === "object"
              ? programSection.uuid
              : programSection, // it's just an uuid directly
          ))
          .flat()
          .join(",");

      if (uuidsList) {
        allComponents.getBlocks(uuidsList).then((response) => {
          const sectionDataNew = response.data.stories;

          programSectionsLoaded(sectionDataNew);
          setSectionData(sectionDataNew);
        });
      } else {
        // Added this logic to handle case where resolution depth not adequate for homepage (workaround)
        const programmesBlocksUuids = data.programmes_blocks
          .map((block) => block.uuid)
          .join(",");
        allComponents.getBlocks(programmesBlocksUuids);
      }
    }
  }, [
    data,
    isCareerSeekersProgrammes,
    isEmployersProgrammes,
    programmesLoaded,
    programSectionsLoaded,
  ]);

  const hasDescription =
    data.note_description &&
    data.note_description.content[0] &&
    data.note_description.content[0].content;

  const onToggleButtonPressed = () => {
    setIsExpanded(!isExpanded);
  };
  const onPDFDownloadPressed = () => {
    window.open(
      formatAssetUrl(data.programmes_link.url || data.programmes_link.cached_url),
      "_self",
    );
  };
  return (
    <>
      <div className="page-section_head">
        <div className="page-section_head__holder">
          <h2 style={{ color: data.title_color && data.title_color.color }}>
            {data.programmes_title}
          </h2>
          {(isProgrammes ||
            isCareerSeekersProgrammes ||
            isEmployersProgrammes) &&
          (data.programmes_link.url || data.programmes_link.cached_url) &&
          (isCareerSeekersProgrammes ? (
            <ActionButton
              actionText="Expand All"
              unActionText="Collapse All"
              isExpanded={isExpanded}
              onAction={onToggleButtonPressed}
            />
          ) : (
            <a
              href={
                data.programmes_link.url ||
                `/${data.programmes_link.cached_url}/`
              }
            >
              {data.program_link_copy}
            </a>
          ))}
        </div>
        {data.programmes_description && (
          <div
            style={{
              color: data.description_color && data.description_color.color,
            }}
            className="page-section_head__description"
          >
            {data.programmes_description}
          </div>
        )}
      </div>

      <ExploreOurProgrammesList
        data={data}
        component={data.component}
        sectionData={sectionData}
        isExpanded={isExpanded}
      />

      {(data.note_title || hasDescription) && (
        <NoteBlock
          hasDescription={hasDescription}
          title={data.note_title}
          description={data.note_description}
        />
      )}
      {isCareerSeekersProgrammes && (
        <div className="page-section_foot__download-pdf">
          <ActionButton
            actionText="Download a detailed list (PDF)"
            actionIcon="download"
            onAction={onPDFDownloadPressed}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  const { programmes, programSections } = store.programmReducer;
  return { programmes, programSections };
};

const mapDispatchToProps = (dispatch) => {
  return {
    programmesLoaded: (newProgrammes) => {
      dispatch(programmesLoadedAction(newProgrammes));
    },
    programSectionsLoaded: (newProgramSections) => {
      dispatch(programSectionsLoadedAction(newProgramSections));
    },
  };
};

ExploreOurProgrammes.propTypes = {
  data: PropTypes.object,
  programmesLoaded: PropTypes.func,
  programSectionsLoaded: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExploreOurProgrammes);
