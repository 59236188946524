export default [
  {
    title: "INTRODUCTION",
    link: "/career-seekers/introduction",
  },
  { title: "GET STARTED", link: "/career-seekers/get-started" },
  {
    title: "FIND APPRENTICESHIP JOBS",
    link: "/career-seekers/jobs",
  },
  {
    title: "ALREADY AN APPRENTICE?",
    link: "/career-seekers/already-an-apprentice",
  },
];
