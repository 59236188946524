/* eslint-disable react/prop-types */

import React from "react";
import { Redirect } from "react-router-dom";

import Homepage from "../pages/Homepage/Homepage.jsx";
import CareerSeekerJobs from "../pages/FindJobs/Jobs.jsx";
import InstructorJobsPage from "../pages/FindJobs/InstructorJobs.jsx";
import InstructorCareersIntroductionPage from "../pages/InstructorCareersPages/IntroductionPage.jsx";
import CareerSeekersIntroductionPage from "../pages/CareerSeekersPages/IntroductionPage.jsx";
import DiscoverPage from "../pages/CareerSeekersPages/DiscoverPage.jsx";
import LearnMorePage from "../pages/CareerSeekersPages/LearnMorePage.jsx";
import NextStepsPage from "../pages/CareerSeekersPages/NextStepsPage.jsx";
import JobDetailPage from "../pages/CareerSeekersPages/JobDetailPage.jsx";
import AlreadyAnApprenticePage from "../pages/CareerSeekersPages/AlreadyAnApprenticePage.jsx";
import EducatorsPage from "../pages/CareerAdvisorsPages/EducatorsPage.jsx";
import ParentsAndFriendsPage from "../pages/CareerAdvisorsPages/ParentsAndFriendsPage.jsx";
import PotentialEmployersPage from "../pages/EmployersPages/PotentialEmployersPage.jsx";
import FindPage from "../pages/EmployersPages/FindPage.jsx";
import SelectPage from "../pages/EmployersPages/SelectPage.jsx";
import ApplyPage from "../pages/EmployersPages/ApplyPage.jsx";
import CurrentEmployersPage from "../pages/EmployersPages/CurrentEmployersPage.jsx";
import EmployerAmbassadorsPage from "../pages/EmployersPages/EmployerAmbassadorsPage.jsx";
import AllNewsPage from "../pages/NewsEventsPages/AllNewsPage.jsx";
import AllNewsArticlePage from "../pages/NewsEventsPages/AllNewsArticlePage.jsx";
import AnnouncementsPage from "../pages/NewsEventsPages/AnnouncementsPage.jsx";
import CompetitionsPage from "../pages/NewsEventsPages/CompetitionsPage.jsx";
import EventsPage from "../pages/NewsEventsPages/EventsPage.jsx";
import Page404 from "../pages/Page404/Page404.jsx";
import SuccessStories from "../pages/SuccessStory/SuccessStory.jsx";
import About from "../pages/More/About.jsx";
import ApprenticeshipDirectory from "../pages/More/ApprenticeshipDirectory.jsx";
import YourLocalProvider from "../pages/More/YourLocalProvider.jsx";
import TermsPage from "../pages/TermsPage/TermsPage.jsx";

const mainRoutesConfig = [
  {
    path: "/",
    component: Homepage,
    exact: true,
  },
  {
    path: "/homepage",
    render: () => <Redirect to="/" />,
  },
  {
    path: "/pages/homepage",
    render: () => <Redirect to="/" />,
  },
  {
    path: "/pages/instructors-introduction",
    render: () => <Redirect to="/instructors/introduction" />,
  },
  {
    path: "/pages/instructors-jobs",
    render: () => <Redirect to="/instructors/jobs" />,
  },
  {
    path: "/instructors/introduction",
    component: InstructorCareersIntroductionPage,
    exact: true,
  },
  {
    path: "/instructors/jobs",
    component: InstructorJobsPage,
    exact: true,
  },
  {
    path: "/instructors/jobs/:vacancyId",
    component: JobDetailPage,
    exact: true,
  },
  {
    path: "/career-seekers",
    render: () => <Redirect to="/career-seekers/introduction" />,
    exact: true,
  },
  {
    path: "/pages/career-seekers",
    render: () => <Redirect to="/career-seekers/introduction" />,
    exact: true,
  },
  {
    path: "/career-seekers-introduction",
    render: () => <Redirect to="/career-seekers/introduction" />,
    exact: true,
  },
  {
    path: "/pages/career-seekers-introduction",
    render: () => <Redirect to="/career-seekers/introduction" />,
    exact: true,
  },
  {
    path: "/career-seekers/introduction",
    component: CareerSeekersIntroductionPage,
    exact: true,
  },
  {
    path: "/career-seekers/get-started",
    render: () => <Redirect to="/career-seekers/get-started/discover" />,
    exact: true,
  },
  {
    path: "/discover",
    render: () => <Redirect to="/career-seekers/get-started/discover" />,
    exact: true,
  },
  {
    path: "/pages/discover",
    render: () => <Redirect to="/career-seekers/get-started/discover" />,
    exact: true,
  },
  {
    path: "/career-seekers/get-started/discover",
    component: DiscoverPage,
    exact: true,
  },
  {
    path: "/career-seekers-jobs",
    render: () => <Redirect to="/career-seekers/jobs" />,
    exact: true,
  },
  {
    path: "/pages/career-seekers-jobs",
    render: () => <Redirect to="/career-seekers/jobs" />,
    exact: true,
  },
  {
    path: "/jobs",
    render: () => <Redirect to="/career-seekers/jobs" />,
    exact: true,
  },
  {
    path: "/career-seekers/jobs",
    component: CareerSeekerJobs,
    exact: true,
  },
  {
    path: "/career-seekers/jobs/:vacancyId",
    component: JobDetailPage,
    exact: true,
  },
  {
    path: "/career-seekers/get-started/learn-more",
    render: () => <Redirect to="/career-seekers/get-started/discover" />,
    exact: true,
  },
  {
    path: "/career-seekers/get-started/learn-more/:program/:section",
    render: ({ match }) => {
      return (
        <LearnMorePage
          programSlug={match.params.program}
          sectionSlug={match.params.section}
        />
      );
    },
  },
  {
    path: "/career-seekers/get-started/next-steps",
    render: () => <Redirect to="/career-seekers/get-started/discover" />,
    exact: true,
  },
  {
    path: "/career-seekers/get-started/next-steps/:program/:section",
    render: ({ match }) => {
      return (
        <NextStepsPage
          programSlug={match.params.program}
          sectionSlug={match.params.section}
        />
      );
    },
  },
  {
    path: "/career-seekers/already-an-apprentice",
    component: AlreadyAnApprenticePage,
    exact: true,
  },
  {
    path: "/career-advisors",
    render: () => <Redirect to="/career-advisors/educators" />,
    exact: true,
  },
  {
    path: "/educators",
    render: () => <Redirect to="/career-advisors/educators" />,
    exact: true,
  },
  {
    path: "/pages/educators",
    render: () => <Redirect to="/career-advisors/educators" />,
    exact: true,
  },
  {
    path: "/career-advisors/educators",
    component: EducatorsPage,
    exact: true,
  },
  {
    path: "/parents-and-friends",
    render: () => <Redirect to="/career-advisors/parents-and-friends" />,
    exact: true,
  },
  {
    path: "/pages/parents-and-friends",
    render: () => <Redirect to="/career-advisors/parents-and-friends" />,
    exact: true,
  },
  {
    path: "/career-advisors/parents-and-friends",
    component: ParentsAndFriendsPage,
    exact: true,
  },
  {
    path: "/employers",
    render: () => <Redirect to="/employers/potential-employers" />,
    exact: true,
  },
  {
    path: "/pages/potential-employers",
    render: () => <Redirect to="/employers/potential-employers" />,
    exact: true,
  },
  {
    path: "/potential-employers",
    render: () => <Redirect to="/employers/potential-employers" />,
    exact: true,
  },
  {
    path: "/employers/potential-employers",
    component: PotentialEmployersPage,
    exact: true,
  },
  {
    path: "/employers/how-to-apply",
    render: () => <Redirect to="/employers/how-to-apply/find" />,
    exact: true,
  },
  {
    path: "/pages/find",
    render: () => <Redirect to="/employers/how-to-apply/find" />,
    exact: true,
  },
  {
    path: "/employers/how-to-apply/find",
    component: FindPage,
    exact: true,
  },
  {
    path: "/employers/how-to-apply/select",
    render: () => <Redirect to="employers/how-to-apply/find" />,
    exact: true,
  },
  {
    path: "/employers/how-to-apply/select/:program/:section",
    render: ({ match }) => {
      return (
        <SelectPage
          programSlug={match.params.program}
          sectionSlug={match.params.section}
        />
      );
    },
  },
  {
    path: "/employers/how-to-apply/apply",
    render: () => <Redirect to="/employers/how-to-apply/find" />,
    exact: true,
  },
  {
    path: "/employers/how-to-apply/apply/:program/:section",
    render: ({ match }) => {
      return (
        <ApplyPage
          programSlug={match.params.program}
          sectionSlug={match.params.section}
        />
      );
    },
  },
  {
    path: "/employers/current-employers",
    component: CurrentEmployersPage,
    exact: true,
  },
  {
    path: "/employers/employer-ambassadors",
    component: EmployerAmbassadorsPage,
    exact: true,
  },
  {
    path: "/news-events",
    render: () => <Redirect to="/news-events/news" />,
    exact: true,
  },
  {
    path: "/news-events/news",
    component: AllNewsPage,
    exact: true,
  },
  {
    path: "/news-events/news/:id",
    render: ({ match }) => {
      return <AllNewsArticlePage id={match.params.id} />;
    },
  },
  {
    path: "/success-stories",
    render: () => <Redirect to="/" />,
    exact: true,
  },
  {
    path: "/success-stories/:id",
    render: ({ match }) => {
      return <SuccessStories id={match.params.id} />;
    },
  },
  {
    path: "/news-events/competitions",
    component: CompetitionsPage,
    exact: true,
  },
  {
    path: "/news-events/events",
    component: EventsPage,
    exact: true,
  },
  {
    path: "/news-events/announcements",
    component: AnnouncementsPage,
    exact: true,
  },
  {
    path: "/more",
    render: () => <Redirect to="/more/about" />,
    exact: true,
  },
  {
    path: "/more/about",
    component: About,
    exact: true,
  },
  {
    path: "/more/apprenticeship-directory",
    component: ApprenticeshipDirectory,
    exact: true,
  },
  // {
  //   path: "/more/e-learning-portal",
  //   component: ELearningPortal,
  //   exact: true,
  // },
  {
    path: "/more/your-local-provider",
    component: YourLocalProvider,
    exact: true,
  },
  {
    path: "/terms",
    component: TermsPage,
    exact: true,
  },
  {
    path: "**",
    component: Page404,
  },
];

export default mainRoutesConfig;
