import React from "react";
import PropTypes from "prop-types";

import HeroSlider from "../HeroSlider";
import HeroButtons from "../HeroButtons";

import "./HeroMain.scss";

const HeroMain = ({ data }) => {
  return (
    <section className="hero-main">
      {data.blocks && <HeroSlider data={data.blocks} />}
      <div className="description__wrapper">
        <h1>{data.hero_title}</h1>
        <h2>{data.hero_description}</h2>

        {data.component && <HeroButtons data={data} />}
      </div>
    </section>
  );
};

HeroMain.propTypes = {
  data: PropTypes.shape({
    hero_title: PropTypes.string,
    hero_description: PropTypes.string,
    blocks: PropTypes.array,
    component: PropTypes.string,
  }),
};

export default HeroMain;
