import React from "react";
import CMSPageData from "../../components/CMSPageData/CMSPageData";
import SectionTitleMenu from "../../components/SectionTitleMenu";

import ParentsEducators from "../../assets/img/CareerAdvisors/ParentsEducators.svg";
import ParentsEducatorsPeople from "../../assets/img/CareerAdvisors/ParentsEducators-people.svg";
import CareerAdvisorsMenuConfig from "../../config/CareerAdvisorsMenuConfig";

export default () => {
  return (
    <>
      <SectionTitleMenu menuList={CareerAdvisorsMenuConfig} />
      <div className="educators">
        <CMSPageData
          propsData={{
            img: ParentsEducators,
            childImage: ParentsEducatorsPeople,
            className: "parents-educators",
          }}
          pageName={"educatorsPage"}
        />
      </div>
    </>
  );
};
