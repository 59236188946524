import { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Hook that handle clicks outside of the passed ref
 */
const useOutsideClick = ({ ref, toggled, callback }) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        toggled &&
        e.target instanceof HTMLElement &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, toggled, callback]);
};

useOutsideClick.propTypes = {
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  toggled: PropTypes.bool,
  callback: PropTypes.func,
};

export default useOutsideClick;
