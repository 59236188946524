const getEventDate = (dateValue) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const shapeNumber = (number) => {
    const getNumber = +number;
    return getNumber < 10 ? `0${getNumber}` : getNumber;
  };

  const shapeDate = (date, month, year, hours, minutes) => {
    const shapeDay = shapeNumber(date.getDate());
    const shapeHours = hours > 12 ? hours - 12 : hours;
    const metaTime = hours > 12 ? "PM" : "AM";
    return `${shapeDay} ${month} ${year} | ${shapeNumber(
      shapeHours,
    )}:${minutes}${metaTime}`;
  };

  let dateReturn = null;

  if (dateValue.split(" ").length === 1) {
    const dateFull = dateValue ? new Date(dateValue) : new Date();
    const month = monthNames[dateFull.getMonth()];
    const year = dateFull.getFullYear();

    dateReturn = shapeDate(
      dateFull,
      month,
      year,
      dateFull.getHours(),
      shapeNumber(dateFull.getMinutes()),
    );
  } else {
    const [getDate, getTime] = dateValue.split(" ");
    const [getHours, getMinutes] = getTime.split(":");
    const dateFull = dateValue ? new Date(getDate.replace(/-/g, "/")) : null;
    const month = monthNames[dateFull.getMonth()];
    const year = dateFull.getFullYear();

    dateReturn = shapeDate(dateFull, month, year, getHours, getMinutes);
  }

  return dateReturn;
};

export default getEventDate;
