import React from "react";
import PropTypes from "prop-types";

import styles from "./DecorationLetter.scss";

const DecorationLetter = ({ color, size }) => {
  return (
    <svg
      width={`${size}px`}
      viewBox="0 0 125 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="decoration-letter"
    >
      <path
        d="M96.7537 204.802L123 194.448M96.7537 204.802L64.7243 196.973M96.7537 204.802L93.8621 185.861M123 194.448L93.8621 185.861M123 194.448L95.864 12.3548M93.8621 185.861L64.7243 196.973M93.8621 185.861L89.4136 155.807M64.7243 196.973L62.7846 167.172M62.7846 167.172L89.4136 155.807M62.7846 167.172L37.3658 160.605M89.4136 155.807L62.9449 150.25M89.4136 155.807L69.1728 22.9621M62.9449 150.25L37.3658 160.605M62.9449 150.25L62.6841 177.779M37.3658 160.605L34.4743 189.902M34.4743 189.902L2 181.82M34.4743 189.902L62.6841 177.779M2 181.82L31.8051 13.6176M2 181.82L29.8033 170.707M31.8051 13.6176L69.1728 22.9621M31.8051 13.6176L57.6066 2M69.1728 22.9621L95.864 12.3548M57.6066 2L95.864 12.3548M57.6066 2L29.8033 170.707M29.8033 170.707L62.6841 177.779"
        stroke={color}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.8192 62.3613L70.7291 50.4912M47.8192 62.3613L42.9258 130.551M47.8192 62.3613L56.4938 133.582M70.7291 50.4912L76.7346 120.702M70.7291 50.4912L64.7236 118.176M76.7346 120.702L56.4938 133.582M76.7346 120.702L64.7236 118.176M56.4938 133.582L42.9258 130.551M42.9258 130.551L64.7236 118.176"
        stroke={color}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

DecorationLetter.defaultProps = {
  size: 120,
  color: styles.white,
};

DecorationLetter.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default DecorationLetter;
