import React from "react";
import PropTypes from "prop-types";

import "./_About.scss";

const About = ({ data }) => (
  <div className="about">
    <h2 style={{ color: data.title_color && data.title_color.color }}>
      {data.title}
    </h2>

    <div className="about__content">
      <p>{data.text}</p>
    </div>
  </div>
);

About.propTypes = {
  data: PropTypes.object,
};

export default About;
