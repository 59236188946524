import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

import Loader from "../Loader";

import "./SearchTool.scss";

const SearchTool = ({ handleSearchSubmit, handleSearchClear }) => {
  /* State for searchTool input value */
  const [searchInputValue, setSearchInputValue] = useState("");

  const [isError, setError] = useState(false);

  const [isSearching, setSearching] = useState(false);

  /* Fn to changes searchTool value */
  const handleSearchInput = () => (event) => {
    const value = event.target.value;
    setSearchInputValue(value);
  };

  const handleSearchOnSubmit = (event) => {
    event.preventDefault();
    setSearching(true);
    if (searchInputValue === "") {
      handleSearchClear();
      setError(true);
      setSearching(false);
      return;
    }
    handleSearchSubmit(searchInputValue);
    setError(false);
    setSearching(false);
  };

  const handleSearchOnClear = () => {
    setSearchInputValue("");
    setError(false);
    handleSearchClear();
  };

  const mainClass = classNames({
    "search-tool": true,
    "is-error": isError,
  });

  const btnClass = classNames({
    "search-btn": true,
    "is-searching": isSearching,
  });

  return (
    <form
      className={mainClass}
      onSubmit={handleSearchOnSubmit}
      autoComplete="off"
    >
      <div className="search-tool__holder">
        <label htmlFor="search-input" className="search-input">
          <FontAwesomeIcon icon={faSearch} />

          <input
            type="input"
            value={searchInputValue}
            onChange={handleSearchInput()}
            placeholder={
              isError ? "Enter a keyword to search" : "Find an article"
            }
            id="search-input"
          />

          {searchInputValue && (
            <div className="search-tool__close" onClick={handleSearchOnClear}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </label>
        <button className={btnClass}>
          {isSearching ? "Searching" : "Search"}
          <span>{isSearching ? <Loader isSmall /> : "⟶"}</span>
        </button>
      </div>
    </form>
  );
};

SearchTool.propTypes = {
  handleSearchSubmit: PropTypes.func,
  handleSearchClear: PropTypes.func,
};

export default SearchTool;
