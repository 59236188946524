import React from "react";

import SectionTitleMenu from "../../components/SectionTitleMenu";
import CMSPageData from "../../components/CMSPageData/CMSPageData";
import MoreSectionMenuConfig from "../../config/MoreSectionMenuConfig";
import SkewTopFooter from "../../components/Skews/SkewTopFooter";

export default () => (
  <>
    <SectionTitleMenu menuList={MoreSectionMenuConfig} />
    <CMSPageData pageName="about" />
    <SkewTopFooter />
  </>
);
