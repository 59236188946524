import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { animateScroll as scroll } from "react-scroll";

import useWindowDimensions from "../../hooks/windowViewPort";

import SocialNetworks from "../Socials/SocialNetworks.jsx";

import SolasFooterLogo from "../../assets/img/Footer/solas-learning-works.png";
import IrelandGovernmentLogo from "../../assets/img/Footer/government-of-Ireland.png";

import "./_Footer.scss";

const Footer = () => {
  /**
   * Fn to put User to top of pages
   * @returns void
   */
  const backToTop = () => {
    scroll.scrollToTop();
  };

  const footerLogoData = [
    {
      title: "Solas learning works",
      imgSrc: SolasFooterLogo,
    },
    {
      title: "Rialtas na hÉireann",
      imgSrc: IrelandGovernmentLogo,
    },
  ];

  /* shows share SocialNetworks buttons */

  return (
    <footer className="main-footer ">
      <section className="top-line">
        <div className="container">
          <div id="__ba_launchpad"></div>
          <button
            className="back-to-top-btn"
            aria-label="Press to lifts to the top"
            onClick={backToTop}
          >
            Back to the Top
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </div>
      </section>

      <section className="footer-logos">
        <ul className="footer-logos-list">
          {footerLogoData.map((props, index) => (
            <li key={index}>
              <img src={props.imgSrc} alt={props.title} />
            </li>
          ))}
        </ul>
      </section>

      <section className="about-us">
        <div className="container">
          <p className="about-us__title">
            <Link to="/terms">Terms & Conditions</Link>

            <a
              href=" https://www.solas.ie/freedom-of-information/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Freedom of Information
            </a>
            <button
              className="changePreferences"
              onClick={() => window.cookiePreferenceShortcut()}
              id="changePreferences"
            >
              Cookie Preferences
            </button>
          </p>
          {useWindowDimensions("width") > 1024 && <p>Find us here:</p>}

          <SocialNetworks
            showIcons={{
              facebook: true,
              youtube: true,
              twitter: true,
              linkedin: true,
              instagram: true,
            }}
          />

          <p className="about-us__copyright">
            <span>&#169;Copyright 2020</span>
            <span>Generation Apprenticeship</span>
          </p>
        </div>
      </section>
    </footer>
  );
};

Footer.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
};

export default Footer;
