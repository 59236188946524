import React from "react";
import PropTypes from "prop-types";

import LinkSecondary from "../Links/LinkSecondary";
import DecorationSquare from "../Decorations/DecorationSquare";
import getEventDate from "../../utils/get-event-date";

import styles from "./AllNewsPromo.scss";

const AllNewsPromo = ({ data }) => {
  const promoData = data && data.blocks;

  if (!promoData) return null;

  const isEventsView = promoData.content.component === "events_item";
  let getEventStartData = null;

  if (isEventsView) {
    getEventStartData = promoData.content.item_date
      ? promoData.content.item_date
      : null;
  }

  return (
    <section className="all-news-promo">
      <div className="all-news-promo__frame">
        <div className="all-news-promo__visual">
          <img
            src={promoData.content.page_image}
            alt={promoData.content.item_title}
          />
          <DecorationSquare color={styles["green-300"]} width={120} />
        </div>

        <div className="all-news-promo__content">
          <section className="all-news-promo__description">
            <h2>{promoData.content.item_title}</h2>

            {promoData.content.component && (
              <div className="all-news-promo__meta-holder">
                {getEventStartData && (
                  <time>{getEventDate(getEventStartData)}</time>
                )}
                {data.item_location && (
                  <span className="location">
                    {promoData.content.item_location}
                  </span>
                )}
              </div>
            )}

            <div className="description">
              {promoData.content.item_description}
            </div>

            <div className="all-news-promo__link">
              <LinkSecondary
                href={`/news-events/news/${promoData.slug}`}
                text={promoData.content.item_url_name}
              />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

AllNewsPromo.propTypes = {
  data: PropTypes.object,
};

export default AllNewsPromo;
