import React from "react";

import SkewTopFooter from "../../components/Skews/SkewTopFooter";
import CMSPageData from "../../components/CMSPageData";

export default () => (
  <>
    <CMSPageData pageName="termsPage" />
    <SkewTopFooter />
  </>
);
