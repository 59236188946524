import React, { useRef } from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import useOutsideClick from "../../hooks/useOutsideClick";
import "./_MainMenu.scss";

const MainMenu = ({
  mainMenuRoutesData,
  showSubmenu,
  openCloseSubMenuHandler,
  closeSubMenuHandler,
  MainMenuSubRoutesConfig,
}) => {
  const menuRef = useRef(null);

  useOutsideClick({
    ref: menuRef,
    toggled: showSubmenu.isShowSubMenu,
    callback: closeSubMenuHandler,
  });

  return (
    <ul className="user-main-menu">
      {mainMenuRoutesData.map((props) => {
        const mainClass = classNames({
          "submenu-open": showSubmenu.isShowSubMenu && props.text === "More",
          "submenu-item": props.text === "More",
        });
        return (
          <li key={props.id} className={mainClass} ref={menuRef}>
            {props.text !== "More" ? (
              <NavLink
                to={props.link}
                className={props.isActive ? "active-item" : ""}
                onClick={closeSubMenuHandler}
              >
                {props.text}
              </NavLink>
            ) : (
              <span
                className={showSubmenu.isShowSubMenu ? "active-item" : ""}
                onClick={openCloseSubMenuHandler}
              >
                {props.text}
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            )}

            {props.isSubmenu ? (
              <>
                <div
                  className={
                    showSubmenu.isShowSubMenu
                      ? "main-sub-menu__wrapper submenu-open"
                      : "main-sub-menu__wrapper"
                  }
                  ref={menuRef}
                />

                <ul
                  className={
                    showSubmenu.isShowSubMenu
                      ? "main-sub-menu submenu-open"
                      : "main-sub-menu"
                  }
                >
                  <li>More</li>
                  {MainMenuSubRoutesConfig.map((propsSub) => (
                    <li key={propsSub.text}>
                      <NavLink
                        to={propsSub.link}
                        onClick={
                          props.isSubmenu
                            ? openCloseSubMenuHandler
                            : closeSubMenuHandler
                        }
                      >
                        {propsSub.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </li>
        );
      })}
    </ul>
  );
};

MainMenu.propTypes = {
  mainMenuRoutesData: PropTypes.array,
  showSubmenu: PropTypes.object,
  openCloseSubMenuHandler: PropTypes.func,
  closeSubMenuHandler: PropTypes.func,
  MainMenuSubRoutesConfig: PropTypes.array,
  text: PropTypes.string,
  isSubmenu: PropTypes.bool,
  isActive: PropTypes.bool,
  link: PropTypes.string,
  id: PropTypes.string,
};

export default MainMenu;
