import React from "react";
import CMSPageData from "../../components/CMSPageData";
import SectionTitleMenu from "../../components/SectionTitleMenu";
import CareerSeekersSectionMenuConfig from "../../config/InstructorCareersSectionMenuConfig";

export default () => {
  return (
    <>
      <SectionTitleMenu menuList={CareerSeekersSectionMenuConfig} />
      <div className="jobs">
        <CMSPageData
          propsData={{
            className: "jobs",
          }}
          pageName={"instructorsJobs"}
        />
      </div>
    </>
  );
};
