import React from "react";
import PropTypes from "prop-types";

import LinkDouble from "../Links/LinkDouble";
import CompanyLogosSection from "../CompanyLogosSection";
import DecorationLetter from "../Decorations/DecorationLetter";

import styles from "./CompanyLogos.scss";

const CompanyLogos = ({ data }) => {
  return (
    <>
      <div className="company-logos">
        {data.title && (
          <div className="page-section_head">
            <h2>{data.title}</h2>
          </div>
        )}

        <ul className="company-logos__holder">
          <CompanyLogosSection data={data} />
          <li className="btn-item">
            <DecorationLetter color={styles["green-300"]} />

            {data.btn_text && (
              <LinkDouble href={data.company_url} text={data.btn_text} />
            )}
          </li>
        </ul>
      </div>
    </>
  );
};

CompanyLogos.propTypes = {
  data: PropTypes.object,
};

export default CompanyLogos;
