import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames/bind";

import steppersLoadedAction from "../../store/steppers/actions";
import "./SteppersSubNav.scss";

const SteppersSubNav = ({ data, steppersLoaded }) => {
  useEffect(() => {
    steppersLoaded(data);
  }, [data, steppersLoaded]);

  if (!data) return null;
  const getColor = data.step_title_color.color;
  const path = window.location.pathname.split("/");

  const isSecondStepActive =
    path.includes("learn-more") || path.includes("select");

  const backURL = path.includes("select")
    ? "/employers/how-to-apply/find"
    : "/career-seekers/get-started/discover";

  const steppersConfig = [
    {
      title: data.step_one_title,
      description: data.step_one_description,
      isActive: path.includes("discover") || path.includes("find"),
    },
    {
      title: data.step_two_title,
      description: data.step_two_description,
      isActive: isSecondStepActive,
    },
    {
      title: data.step_three_title,
      description: data.step_three_description,
      isActive: path.includes("next-steps") || path.includes("apply"),
    },
  ];

  const renderContent = (item, idx) => (
    <>
      <div style={{ color: getColor }} className="steppers-sub-nav__count">{`${
        idx + 1
      }.`}</div>
      <strong style={{ color: getColor }} className="steppers-sub-nav__title">
        {item.title}
      </strong>
      <div className="steppers-sub-nav__description">{item.description}</div>
      <span style={{ backgroundColor: getColor }} className="decoration-line" />
    </>
  );

  return (
    <ul className="steppers-sub-nav">
      {steppersConfig.map((item, idx) => (
        <li
          key={item.title}
          className={classNames({
            "current-active": item.isActive,
          })}
        >
          {idx === 0 && isSecondStepActive && (
            <Link className="steppers-sub-nav__holder" to={backURL}>
              {renderContent(item, idx)}
            </Link>
          )}
          {(idx !== 0 || !isSecondStepActive) && (
            <div className="steppers-sub-nav__holder">
              {renderContent(item, idx)}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};
const mapStateToProps = (store) => {
  const { steppers } = store.steppersReducer;
  return { steppers };
};
const mapDispatchToProps = (dispatch) => {
  return {
    steppersLoaded: (newSteppers) => {
      dispatch(steppersLoadedAction(newSteppers));
    },
  };
};
SteppersSubNav.propTypes = {
  data: PropTypes.object,
  steppersLoaded: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(SteppersSubNav);
