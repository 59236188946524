import React from "react";
import PropTypes from "prop-types";
import "./_Testimonials.scss";

const Testimonials = ({ data }) => (
  <>
    <div className="page-section_head">
      <div className="page-section_head__holder">
        <h2 style={{ color: data.title_color && data.title_color.color }}>
          {data.title}
        </h2>

        {(data.link.url || data.link.cached_url) && (
          <a href={data.link.url || data.link.cached_url}>{data.link_copy}</a>
        )}
      </div>
    </div>

    <ul className="testimonials-list">
      {data.blocks.map((item) => (
        <li key={item.content.text}>
          <a href={item.content.link_url.url}>{item.content.text}</a>
          <p>{item.content.author}</p>
          <p>{item.content.company}</p>
        </li>
      ))}
    </ul>
  </>
);

Testimonials.propTypes = {
  data: PropTypes.object,
};

export default Testimonials;
