import React, { useState, useEffect, useRef } from "react";
import has from "lodash/has";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { richTextResolver } from "../../CMS/Storyblok";

import "./FAQContentItem.scss";

const FAQContentItem = ({ item, isAllExpanded }) => {
  const [openCloseState, setOpenCloseState] = useState(false);
  const [styleHeight, setStyleHeight] = useState({});
  const [tableContent, setTableContent] = useState([]);

  const refHolder = useRef(null);
  const itemClass = classNames({
    "faq-list__item-question": true,
    open: openCloseState,
  });

  const handleOpenClose = () => setOpenCloseState(!openCloseState);

  const isPriceList =
    has(item, "content.price_list_table") && item.content.price_list_table;

  useEffect(() => {
    setOpenCloseState(isAllExpanded);
  }, [isAllExpanded]);

  useEffect(() => {
    if (!isPriceList) return;

    const table = item.content.price_list_table.tbody.map((row, index) => (
      <tr key={index}>
        {row.body.map((cell, inx) => (
          <td className={inx === 0 ? "title" : "sum"} key={inx}>
            {cell.value}
          </td>
        ))}
      </tr>
    ));
    setTableContent(table);
  }, [item, isPriceList]);

  useEffect(() => {
    let height = openCloseState && refHolder.current.clientHeight;
    height = { maxHeight: openCloseState ? `${height}px` : 0 };
    setStyleHeight(height);
  }, [openCloseState, tableContent, item]);

  return (
    <>
      <button className={itemClass} onClick={handleOpenClose}>
        {item.content.question}
        <span className="indicator" />
      </button>

      <section className="faq-list__item-answer" style={styleHeight}>
        <div className="faq-list__holder" ref={refHolder}>
          <div
            className="faq-list__content"
            dangerouslySetInnerHTML={{
              __html: richTextResolver(item.content.answer),
            }}
          />
          {isPriceList && (
            <div className="price-list">
              <h5>{item.content.price_list_title}</h5>
              <table>
                <tbody>{tableContent}</tbody>
              </table>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

FAQContentItem.propTypes = {
  item: PropTypes.object,
  isAllExpanded: PropTypes.bool,
};

export default FAQContentItem;
