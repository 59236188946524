import { storyRequest } from "./Storyblok";

export const homepage = () => storyRequest("pages/homepage", "cdn/stories");

export const parentsAndFriendsPage = () =>
  storyRequest("pages/parents-and-friends", "cdn/stories");

export const careerSeekersIntroduction = () =>
  storyRequest("pages/career-seekers-introduction", "cdn/stories");

export const careerSeekersJobs = () =>
  storyRequest("pages/career-seekers-jobs", "cdn/stories");

export const instructorsIntroduction = () =>
  storyRequest("pages/instructors-introduction", "cdn/stories");

export const instructorsJobs = () =>
  storyRequest("pages/instructors-jobs", "cdn/stories");

export const educatorsPage = () =>
  storyRequest("pages/educators", "cdn/stories");

export const potentialEmployersPage = () =>
  storyRequest("pages/potential-employers", "cdn/stories");

export const currentEmployersPage = () =>
  storyRequest("pages/current-employers", "cdn/stories");

export const employerAmbassadorsPage = () =>
  storyRequest("pages/employer-ambassadors", "cdn/stories");

export const alreadyAnApprenticePage = () =>
  storyRequest("pages/already-an-apprentice", "cdn/stories");

// export const eLearningPortalPage = () =>
//   storyRequest("pages/e-learning-portal", "cdn/stories");

export const discover = () => storyRequest("pages/discover", "cdn/stories");

export const find = () => storyRequest("pages/find", "cdn/stories");

export const find2 = () => storyRequest("pages/find-2", "cdn/stories");

export const allNews = () => storyRequest("pages/news", "cdn/stories");

export const competitions = () =>
  storyRequest("pages/competitions", "cdn/stories");

export const events = () => storyRequest("pages/events", "cdn/stories");

export const announcements = () =>
  storyRequest("pages/announcements", "cdn/stories");

export const about = () => storyRequest("pages/about", "cdn/stories");

export const apprenticeshipDirectory = () =>
  storyRequest("pages/apprenticeship-directory", "cdn/stories");

export const yourLocalProvider = () =>
  storyRequest("pages/your-local-provider", "cdn/stories");

export const termsPage = () => storyRequest("pages/terms", "cdn/stories");

export const getBlocks = (sortParams, sortBy) =>
  storyRequest("", "cdn/stories", sortParams, sortBy);
