import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import TextTruncate from "react-text-truncate";

import LinkSecondary from "../Links/LinkSecondary";
import DecorationSquare from "../Decorations/DecorationSquare";
import getEventDate from "../../utils/get-event-date";
import ModalVideo from "../ModalVideo";

import styles from "./Card.scss";

const Card = ({ data }) => {
  const cardData = data.content;
  const isVideoView = cardData.component === "videos_item";
  const isNewsView = cardData.component === "news_item";
  const isEventsView = cardData.component === "events_item";

  /* state to knows when video opens\closes */
  const [videoState, setVideoState] = useState({ isVideoShow: false });

  /**
   * Fn for opens\closes video pop-up;
   */
  const handleCloseVideo = () => {
    setVideoState({ isVideoShow: false });
    document.body.classList.remove("modal-open");
  };

  const handleStartVideo = (event) => {
    if (event) event.preventDefault();
    setVideoState({ isVideoShow: true });
    document.body.classList.add("modal-open");
  };

  const mainClass = classNames({
    card: true,
    "card-video": isVideoView,
    "card-news": isNewsView,
    "card-events": isEventsView,
  });

  const cardVisualClass = classNames({
    card__visual: true,
    "has-link": isVideoView && cardData.item_video_key,
  });

  return (
    <>
      <section className={mainClass}>
        <div className="card__holder">
          <div className={cardVisualClass}>
            {isVideoView ? (
              <div
                onClick={
                  cardData.item_video_key ? () => handleStartVideo() : null
                }
              >
                <img src={cardData.page_image} alt={cardData.item_title} />
              </div>
            ) : (
              <Link to={`/news-events/news/${data.slug}`}>
                <img src={cardData.page_image} alt={cardData.item_title} />
              </Link>
            )}

            {isEventsView && (
              <DecorationSquare color={styles.white} size={60} />
            )}
          </div>

          <div className="card__description">
            {isEventsView && Boolean(cardData.item_date) && (
              <time className="card__date">
                {getEventDate(cardData.item_date)}
              </time>
            )}

            <h4>{cardData.item_title}</h4>

            <div className="card__description__content">
              {!isEventsView && (
                <TextTruncate
                  line={4}
                  element="span"
                  truncateText="…"
                  text={cardData.item_description}
                />
              )}

              {isEventsView && (
                <TextTruncate
                  line={2}
                  element="span"
                  truncateText=""
                  text={cardData.item_location}
                />
              )}
            </div>

            <LinkSecondary
              handleOnClick={
                isVideoView && cardData.item_video_key ? handleStartVideo : null
              }
              text={cardData.item_url_name}
              href={`/news-events/news/${data.slug}`}
            />
          </div>
        </div>
      </section>

      {videoState.isVideoShow && (
        <ModalVideo
          videoURL={cardData.item_video_key}
          handleCloseVideo={handleCloseVideo}
          title={cardData.item_title}
        />
      )}
    </>
  );
};

Card.propTypes = {
  data: PropTypes.object,
};

export default Card;
