import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import has from "lodash/has";

import * as allComponents from "../../CMS/operations.jsx";

import Loader from "../../components/Loader";
import NewsArticleTypeManager from "../../components/NewsArticleTypeManager";
import "../../assets/scss/layouts/article-page.scss";

const AllNewsArticlePage = ({ id }) => {
  const [pageData, setPageData] = useState({});
  const [relatedNewsData, setRelatedNewsData] = useState({});

  useEffect(() => {
    allComponents.getBlocks(`news/*/${id}`, "by_slugs").then((response) => {
      setPageData(response.data.stories[0]);
    });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!has(pageData, "tag_list") || pageData.tag_list.length === 0) return;

    const getRelatedNewsData = (cardsList) => {
      // sort articles list for exclude itself by uuid
      const sortedCardList = cardsList.filter(
        (article) => article.uuid !== pageData.uuid,
      );

      const newData = { blocks: sortedCardList };
      newData.add_link_view_all = {
        cached_url: "/news-events/news",
        url: "/news-events/news",
      };
      newData.sliders_title = "Related News/Events";
      newData.sliders_link_view_all_title = "View All";
      return newData;
    };

    const tagList = pageData.tag_list.join(",");
    allComponents.getBlocks(tagList, "with_tag").then((response) => {
      const cardsList = response.data.stories;

      setRelatedNewsData(getRelatedNewsData(cardsList));
    });
  }, [pageData]);

  return has(pageData, "content") ? (
    <NewsArticleTypeManager
      pageData={pageData}
      relatedNewsData={relatedNewsData}
    />
  ) : (
    <Loader isHolder />
  );
};

AllNewsArticlePage.propTypes = {
  id: PropTypes.string,
};

export default AllNewsArticlePage;
