import React from "react";
import PropTypes from "prop-types";
import LinkDefault from "../../Links/LinkDefault";

const Button = ({ data }) => {
  return (
    <LinkDefault
      text={data.label}
      href={data.link.url || data.link.cached_url}
      isWhiteBorder={data.is_white_border}
      isBlank={data.open_in_new_tab}
    />
  );
};

Button.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.object,
    is_white_border: PropTypes.bool,
    open_in_new_tab: PropTypes.bool,
  }),
};

export default Button;
