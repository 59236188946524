import React from "react";
import PropTypes from "prop-types";

import "./_HiringCompanies.scss";

const HiringCompanies = ({ data }) => (
  <>
    {data.title && (
      <div className="page-section_head">
        <h2 style={{ color: data.title_color && data.title_color.color }}>
          {data.title}
        </h2>
      </div>
    )}

    <ul className="hiring-companies-list">
      {data.blocks.map((item) => {
        let img = (
          <img src={item.content.logo.filename} alt={item.content.name} />
        );
        const getURL =
          item.content.add_link.url || `/${item.content.add_link.cached_url}/`;
        img = getURL ? (
          /* eslint-disable-next-line react/jsx-no-target-blank */ // Disabled, the rule isn't violated
          <a
            href={getURL}
            target={item.content.add_link.url ? "_blank" : null}
            rel={item.content.add_link.url ? "noopener noreferrer" : null}
          >
            {img}
          </a>
        ) : (
          img
        );
        return <li key={item.content.name}>{img}</li>;
      })}
    </ul>
  </>
);

HiringCompanies.propTypes = {
  data: PropTypes.object,
};

export default HiringCompanies;
