export default [
  {
    title: "WHY BECOME AN INSTRUCTOR?",
    link: "/instructors/introduction",
  },
  {
    title: "FIND INSTRUCTOR JOBS",
    link: "/instructors/jobs",
  },
];
