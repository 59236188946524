import React, { useState } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

import SearchTool from "../SearchTool";
import Card from "../Card";

import "./_AllNewsArticles.scss";

const AllNewsArticles = ({ data }) => {
  const sortData = data.blocks.sort((itemA, itemB) => {
    const dateA = new Date(
      itemA.content.override_published_date || itemA.first_published_at,
    );
    const dateB = new Date(
      itemB.content.override_published_date || itemB.first_published_at,
    );
    return dateB - dateA;
  });

  const [dataCards, setDataCards] = useState(sortData);
  const [nothingFound, setNothingFound] = useState("");

  const handleSearchSubmit = (value) => {
    const getSearchCards = sortData.filter((card) => {
      const title = card.content.item_title.toLowerCase();
      const description = card.content.item_description.toLowerCase();
      const valueLowercase = value.toLowerCase();
      return (
        title.includes(valueLowercase) || description.includes(valueLowercase)
      );
    });
    if (getSearchCards.length === 0) {
      setNothingFound(value);
      return;
    }
    setDataCards(getSearchCards);
    setNothingFound("");
  };

  const handleSearchClear = () => {
    setNothingFound("");
    setDataCards(sortData);
  };

  return (
    <div className="all-news-articles__wrapper">
      <section className="all-news-articles">
        <SearchTool
          handleSearchSubmit={handleSearchSubmit}
          handleSearchClear={handleSearchClear}
        />
        {nothingFound && (
          <div className="nothing-found-box">
            <h3>
              We cannot find anything that matches your search:
              <br />
              <strong>{nothingFound}</strong>
            </h3>
            <h3>
              Please check your spelling or try using less specific search
              terms.
            </h3>
          </div>
        )}
        {!nothingFound && (
          <div className="all-news-articles-list">
            {dataCards.map((article) => (
              <LazyLoad key={article.full_slug} offset={[-200, 0]} debounce={0}>
                <Card data={article} />
              </LazyLoad>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

AllNewsArticles.propTypes = {
  data: PropTypes.object,
};

export default AllNewsArticles;
