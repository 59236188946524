import React, { useEffect } from "react";

import CareerSeekersSectionMenuConfig from "../../config/CareerSeekersSectionMenuConfig";
import SectionTitleMenu from "../../components/SectionTitleMenu/SectionTitleMenu.jsx";
import CMSPageData from "../../components/CMSPageData/CMSPageData";

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SectionTitleMenu menuList={CareerSeekersSectionMenuConfig} />

      <section className="discover">
        <CMSPageData pageName={"discover"} />
      </section>
    </>
  );
};
