import React from "react";
import PropTypes from "prop-types";

import "./btn-expand-all.scss";

const BtnExpandAll = ({ onClick, isAllExpanded }) => (
  <button className="btn-expand-all" onClick={onClick}>
    {isAllExpanded ? "Collapse all" : "Expand All"}
  </button>
);

BtnExpandAll.propTypes = {
  onClick: PropTypes.func,
  isAllExpanded: PropTypes.bool,
};

export default BtnExpandAll;
