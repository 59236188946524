export default [
  {
    link: "/more/about",
    text: "About Generation Apprenticeship",
  },
  {
    link: "/more/apprenticeship-directory",
    text: "Directory of Apprenticeship",
  },
  {
    link: "/more/your-local-provider",
    text: "Your Local Apprenticeship Provider",
  },
  // {
  //   link: "/more/e-learning-portal",
  //   text: "eLearning Portal",
  // },
];
